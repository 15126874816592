import { CONNECTION_ASSURANCE_DOCUMENTS, DASHBOARD, ROOT_CONTRACT } from '@/routes';
import { RouteProperties } from '@/types/RouteProperties';
import { Button, Form, StyledLink } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';

import { CommissioningProcessProps } from '../CommissioningProcessProps';
import { getLocalStorageEntryName } from '../wizard/wizard';

export const NextSteps = ({ title, connectionAssurance }: RouteProperties & CommissioningProcessProps) => (
    <Form title={title}>
        <h4 className="font-bold text-subheading">Nächste Schritte</h4>
        <div className="flex flex-col w-full gap-y-16">
            <div className="flex flex-col gap-y-5 w-full">
                <span>
                    Geschafft - vielen Dank, dass Sie die Inbetriebsetzung für den{' '}
                    <strong>Vorgang {connectionAssurance.processCommunicationId}</strong> abgeschlossen haben. Das
                    Inbetriebsetzungsprotokoll sowie alle weiteren Dokumente zum Vorgang finden Sie in der{' '}
                    <StyledLink
                        href={generatePath(`${ROOT_CONTRACT}${CONNECTION_ASSURANCE_DOCUMENTS}`, {
                            connectionAssuranceId: connectionAssurance.id,
                        })}
                        target="_blank"
                    >
                        Dokumentenverwaltung
                    </StyledLink>
                    .
                </span>
                <span>
                    Haben Sie noch Fragen an uns? Dann melden Sie sich gern unter der Telefonnummer 0361-652-3626.
                </span>
                <span>
                    Ihr Kunde und ggf. Projektpartner wird über die Inbetriebsetzung der Anlage per E-Mail in Kenntnis
                    gesetzt.
                </span>
            </div>

            <Button
                type="primary"
                className="place-self-center"
                label="Zur Übersicht"
                onClick={() => {
                    localStorage.removeItem(getLocalStorageEntryName(connectionAssurance.id));
                    navigateToUrl(DASHBOARD);
                }}
            />
        </div>
    </Form>
);
