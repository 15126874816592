import { ConnectionRelevantDocumentFileCategory } from '@/types/FileUpload';
import { InfoI, StyledLink } from '@ten-netzkundenportal/ui-components';
import React, { MutableRefObject } from 'react';

import PrintOutLink from '../PrintOutLink';

export default (
    fileCategory: ConnectionRelevantDocumentFileCategory,
    boundaryDefiningParentRef: MutableRefObject<HTMLElement>,
) => {
    switch (fileCategory) {
        case 'connectionRegistration':
            return (
                <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Anschluss/ANS_Anmeldung_Formblatt_Strom.pdf" />
            );
        case 'wiringOverviewDiagram':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Übersichtsschaltplan des Anschlusses der Erzeugungsanlage mit den Daten der eingesetzten
                                Betriebsmittel inklusive der Anordnung der Mess- und Schutzeinrichtungen.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        case 'provisionOrderForMeasuringAndBillingTransformator':
            return (
                <>
                    <br />
                    (hier finden Sie den Vordruck für den{' '}
                    <StyledLink
                        href="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/td33_auftrag_bereitst_wandler.pdf"
                        target="_blank"
                    >
                        Auftrag
                    </StyledLink>{' '}
                    und das{' '}
                    <StyledLink
                        href="https://www.thueringer-energienetze.com/Content/Documents/Energiepartner/TEN_Preisblatt_Z_Strom.pdf"
                        target="_blank"
                    >
                        Preisblatt
                    </StyledLink>
                    )
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Anfallende Kosten für eine zusätzliche Wicklung der Spannungswandler für Schutz- und
                                    Fernwirktechnik sind dem Preisblatt Za zu entnehmen.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );
        case 'technicalDatasheet':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Technisches Datenblatt zu geplanten Wechselrichtern, Generatoren, BHKW-Modulen oder
                                Energiespeichersystemen, sofern diese Anlagenkomponenten im Schritt 2 neu angelegt
                                wurden.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        case 'unitsCertificate':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Einheitenzertifikat zu geplanten Wechselrichtern, Generatoren, BHKW-Modulen oder
                                Energiespeichersystemen, sofern diese Anlagenkomponenten im Schritt 2 neu angelegt
                                wurden.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        case 'connectionAndPlantProtectionCertificate':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Zertifikat für den Netz- und Anlagenschutz zu geplanten Wechselrichtern, Generatoren,
                                BHKW-Modulen oder Energiespeichersystemen, sofern diese Anlagenkomponenten im Schritt 2
                                neu angelegt wurden.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        case 'datasheetVDE-AR-N4110/4120':
            return (
                <>
                    <br />
                    (hier finden Sie den Vordruck für die{' '}
                    <StyledLink
                        href="https://www.vde.com/resource/blob/1798770/5630224a6a17c4a2ce11867ed66fd77b/vde-ar-n-4110-formulare-anhang-e-data.pdf"
                        target="_blank"
                    >
                        VDE-AR-N 4110
                    </StyledLink>{' '}
                    und für die{' '}
                    <StyledLink
                        href="https://www.vde.com/resource/blob/1798774/049089d784e60b533cab2157abec20e0/vde-ar-n-4120-formulare-anhang-e-data.pdf"
                        target="_blank"
                    >
                        VDE-AR-N 4120
                    </StyledLink>
                    )
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Voraussetzung für Übergabe des Datenabfragebogens Netzbetreiber durch den
                                    Netzbetreiber.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );
        case 'stationSitePlan':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Maßstabsgerechter Plan vom Aufstellungsort der Übergabe-/Transformatorenstation
                                (Anschlussnehmerstation) oder des Umspannwerks.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        case 'transformerTestReport':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Prüfbericht des/der für die Netzeinbindung verwendeten Transformators/en.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        case 'buildingOrPlantPermit':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Bestellung der Anlage und gültige Baugenehmigung oder und eine Anlagengenehmigung nach
                                dem BImSchG bzw. einen entsprechenden Vorbescheid, aus dem sich die
                                öffentlich-rechtliche Zulässigkeit des Anlagenvorhabens ergibt – soweit dies gesetzlich
                                erforderlich ist.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        case 'isolatedOperation':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Beschreibung der Art und Betriebsweise und der Art der Zuschaltung zum Netz für den
                                Inselbetrieb.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        case 'plantCertificate':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Konformitätserklärung nach Inbetriebnahme der Erzeugungsanlage.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        default:
            return undefined;
    }
};
