import { PlusCircle } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { Link } from 'react-router-dom';

interface DocumentUploadEntryProps {
    uploadDocumentsPath: string;
}
const DocumentUploadEntry = ({ uploadDocumentsPath }: DocumentUploadEntryProps): React.ReactElement => (
    <div className="flex flex-col gap-y-6 pr-4">
        <h4 className="font-bold text-subheading">Dokumentenupload</h4>
        <span>Sie möchten Dokumente zu diesem Vorgang hochladen? Dann klicken Sie bitte auf Dokumente hochladen</span>
        <Link className="flex min-w-max items-center font-bold" to={{ pathname: uploadDocumentsPath }}>
            <PlusCircle className="mr-2 h-4 fill-current text-primary" />
            <span className="hover:underline">Dokumente hochladen</span>
        </Link>
    </div>
);
export default DocumentUploadEntry;
