// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#single-spa-application\\:\\@ten-nkp\\/contract-app .checklist-info-i {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    width: 1rem;
    margin-bottom: 0.1rem;
    margin-left: 0.5rem;
}

#single-spa-application\\:\\@ten-nkp\\/contract-app .checklist-info-i.more-margin {
    margin-left: 0.75rem;
}

#single-spa-application\\:\\@ten-nkp\\/contract-app .numbering-container {
    list-style-type: none;
    counter-reset: css-counter 0;
}

#single-spa-application\\:\\@ten-nkp\\/contract-app .numbering-container .numbered-item {
    counter-increment: css-counter 1;
}

#single-spa-application\\:\\@ten-nkp\\/contract-app .numbering-container .numbered-item:before {
    content: counter(css-counter) '. ';
}

#single-spa-application\\:\\@ten-nkp\\/contract-app .full-main-container-width {
    margin-left: -3rem;
    margin-right: -3rem;
}
`, "",{"version":3,"sources":["webpack://./src/base.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;IACrB,4BAA4B;AAChC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".checklist-info-i {\n    display: inline-block;\n    vertical-align: middle;\n    fill: currentColor;\n    width: 1rem;\n    margin-bottom: 0.1rem;\n    margin-left: 0.5rem;\n}\n\n.checklist-info-i.more-margin {\n    margin-left: 0.75rem;\n}\n\n.numbering-container {\n    list-style-type: none;\n    counter-reset: css-counter 0;\n}\n\n.numbering-container .numbered-item {\n    counter-increment: css-counter 1;\n}\n\n.numbering-container .numbered-item:before {\n    content: counter(css-counter) '. ';\n}\n\n.full-main-container-width {\n    margin-left: -3rem;\n    margin-right: -3rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
