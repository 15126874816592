import { CommissioningStationDocumentsFileCategory } from '@/types/FileUpload';
import { UploadFileCategory } from '@ten-netzkundenportal/ui-document-upload';
import { MutableRefObject } from 'react';

import getContentPerFileCategory from './getContentPerFileCategory';

type Option = {
    label: string;
    value: CommissioningStationDocumentsFileCategory;
};

export function getCommissioningStationDocumentsFileCategories(): UploadFileCategory[] {
    const options: Option[] = [
        {
            label: 'Formular Anmeldung zur Abnahme/ Inbetriebsetzung',
            value: 'commissioningRegistrationForm',
        },
        {
            label: 'Schutzprüfprotokoll übergeordneter Entkupplungsschutz',
            value: 'higherDecouplingProtectionTestProtocol',
        },
        {
            label: 'Unterlagen zu TAB',
            value: 'documentsForTAB',
        },
    ];

    return options
        .filter((option) => !!option)
        .map((option) => ({
            ...option,
            createDescription: (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategory(option.value, boundaryDefiningParentRef),
            tags: { category: 'commissioningStationDocuments', subCategory: option.value },
        }));
}
