import { OtherDocumentsFileCategory } from '@/types/FileUpload';
import { InfoI } from '@ten-netzkundenportal/ui-components';
import React, { MutableRefObject } from 'react';

import PrintOutLink from '../PrintOutLink';

export default (fileCategory: OtherDocumentsFileCategory, boundaryDefiningParentRef: MutableRefObject<HTMLElement>) => {
    switch (fileCategory) {
        case 'houseConnectionPhotoDocumentation':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Fotos vom Zählerschrank sowie des Hausanschlusskastens.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        case 'chargingInfrastructureDataCollectionSheet':
            return (
                <>
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Anschluss/Datenerfassungsblatt_Ladeinfrastruktur.pdf" />
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Angaben zur geplanten Ladetechnik zwecks leistungsgerechter Auslegung der
                                    Messeinrichtungen und zur Beurteilung möglicher Netzrückwirkungen.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );

        case 'other':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={<span className="hyphens-auto">Hier können Sie sonstige Dokumente bereitstellen.</span>}
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );

        default:
            return undefined;
    }
};
