import { PlantComponent } from '@/types/PlantComponent';

export function getAllPlantComponents(
    plantComponents?: PlantComponent[],
    plantComponentsGenerator?: PlantComponent[],
    plantComponentsStorage?: PlantComponent[],
): PlantComponent[] {
    if (plantComponentsGenerator?.length || plantComponentsStorage?.length) {
        return [...(plantComponentsGenerator ?? []), ...(plantComponentsStorage ?? [])];
    }

    return plantComponents;
}
