import { EventObject, MachineConfig, StateMachine, StateNode, createMachine } from 'xstate';

import { Context } from './context';

export const getStateNodes = (stateNode: StateNode | StateMachine<unknown, unknown, EventObject>): StateNode[] => {
    const { states } = stateNode;
    const nodes = Object.keys(states).reduce((accNodes: StateNode[], stateKey) => {
        const childStateNode = states[stateKey];
        const childStateNodes = getStateNodes(childStateNode);

        accNodes.push(childStateNode, ...childStateNodes);
        return accNodes;
    }, []);
    return nodes;
};

export const getRoutes = (config: MachineConfig<Partial<Context>, never, EventObject>): Array<[string[], string]> => {
    const nodes = getStateNodes(createMachine(config));
    const routes: Array<[string[], string]> = [];
    Object.values(nodes).forEach((node) => {
        if (node.meta && node.meta.path) {
            routes.push([node.path, node.meta.path]);
        }
    });
    return routes;
};
