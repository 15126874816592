import { formatNumber, parseNumberFromString } from '@/utils/formatNumber';

export const defaultNumberInputMinValue = 0.001;
export const defaultNumberInputMaxValue = 999_999.999;

export type ValidateNumberInputOptions = {
    allowDecimalDigits?: boolean;
    minValue?: number;
    maxValue?: number;
    errorMessage?: string;
};

export const validateNumberInput = (input: string, options?: ValidateNumberInputOptions) => {
    const allowDecimalDigits = options?.allowDecimalDigits ?? true;
    const minValue = options?.minValue ?? defaultNumberInputMinValue;
    const maxValue = options?.maxValue ?? defaultNumberInputMaxValue;

    const minFractionDigits = allowDecimalDigits ? 1 : 0;
    const maxFractionDigits = allowDecimalDigits ? 3 : 0;
    const errorMessage =
        options?.errorMessage ??
        `Bitte geben Sie eine Zahl zwischen ${formatNumber(minValue, minFractionDigits, maxFractionDigits)} ` +
            `und ${formatNumber(maxValue, minFractionDigits, maxFractionDigits)} ein.`;

    const numRegex = allowDecimalDigits
        ? /^(?:\d{1,3}(?:\.\d{3})*|\d+)(?:,\d{1,3})?$/
        : /^(?:\d{1,3}(?:\.\d{3})*|\d+)$/;

    if (!numRegex.test(input)) {
        return errorMessage;
    }

    const value = parseNumberFromString(input);
    if (value > maxValue || value < minValue) {
        return errorMessage;
    }

    return true;
};
