import { LoadingSpinner, StyledLink } from '@ten-netzkundenportal/ui-components';
import { downloadBlob } from '@ten-netzkundenportal/ui-document-upload';
import React from 'react';

interface DocumentViewLinkProps {
    documentId: string;
    filename: string;
    loadDocument: (documentId: string) => Promise<{ blob: Blob; type: string }>;
    setShowDownloadError: (b: boolean) => void;
}

const ConnectionAssuranceDocumentViewLink = ({
    filename,
    documentId,
    loadDocument,
    setShowDownloadError,
}: DocumentViewLinkProps) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const openDocument = async () => {
        setIsLoading(true);

        try {
            const { blob, type } = await loadDocument(documentId);
            downloadBlob(blob, type, filename);
        } catch (error) {
            console.error('Failed to load document', error);
            setShowDownloadError(true);
        }

        setIsLoading(false);
    };

    return (
        <div className="w-full flex flex-row min-h-full flex-grow items-center">
            <StyledLink title={filename} className="truncate py-2 break-all pr-4" onClick={() => openDocument()}>
                {filename}
            </StyledLink>
            {isLoading && (
                <div className="w-6 h-6">
                    <LoadingSpinner />
                </div>
            )}
        </div>
    );
};

export default ConnectionAssuranceDocumentViewLink;
