import { Close } from '@ten-netzkundenportal/ui-components';
import React from 'react';

export const EnergyStorageCapacityErrorText = () => (
    <div className="-my-4 flex space-x-5" data-testid="energy-storage-capacity-error">
        <span className="flex w-10 text-primary items-center fill-current" data-testid="cross">
            <Close />
        </span>
        <div className="flex w-full flex-col justify-center items-center">
            Die von Ihnen angegebene nutzbare Speicherkapazität ist zu hoch für die ausgewählte Anlagengröße. Bitte
            überprüfen Sie Ihre Eingabe und passen diese an.
        </div>
    </div>
);
