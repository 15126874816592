// Behaves like an enum
export const STEPS = {
    GENERATION_PLANT: 'GENERATION_PLANT',
    DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
    COMPLETION_CONFIRMATION: 'COMPLETION_CONFIRMATION',
    NEXT_STEPS: 'NEXT_STEPS',
} as const;

// Union type of the values of all fields of the constant STEPS object
export type StepsValue = (typeof STEPS)[keyof typeof STEPS];

export const ALL_STEPS: StepsValue[] = [
    STEPS.GENERATION_PLANT,
    STEPS.DOCUMENT_UPLOAD,
    STEPS.COMPLETION_CONFIRMATION,
    STEPS.NEXT_STEPS,
];

/* Behaves like an enum and holds all subprocesses of the request form.
 * Any process that is finalized by a click of the 'Weiter' button is considered a subprocess.
 * Using the map below, the indices of the step and the index of the subprocess within the step can be retrieved. */
export const SUBPROCESS = {
    PLANT_COMPONENTS_GENERATOR: 'PLANT_COMPONENTS_GENERATOR',
    PLANT_COMPONENTS_STORAGE: 'PLANT_COMPONENTS_STORAGE',

    DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',

    COMPLETION_CONFIRMATION: 'COMPLETION_CONFIRMATION',

    NEXT_STEPS: 'NEXT_STEPS',
} as const;

// Union type of the values of all fields of the constant SUBPROCESS object
export type SubprocessValue = (typeof SUBPROCESS)[keyof typeof SUBPROCESS];

/* Used to retrieve the step and subprocess indices of subprocesses.
 * This can be used, e.g., to conditionally hide steps in the wizard.
 * The first element of the list identifies the step and the second one identifies that of the given subprocess
 * within it.
 * Example: 'PlantComponents' is the first step in the request form and the storage components is asked for in the second subprocess
 *          within it. Using 0-based indexing, PLANT_COMPONENTS_STORAGE thus maps to [0,1].
 *
 *          The syntax for accessing the map would be SUBPROCESS_INDEX_MAPPING[SUBPROCESS.PLANT_COMPONENTS_STORAGEPLANT_COMPONENTS_STORAGE]
 */
export const SUBPROCESS_INDEX_MAPPING: { [key in SubprocessValue]: [number, number] } = {
    PLANT_COMPONENTS_GENERATOR: [0, 0],
    PLANT_COMPONENTS_STORAGE: [0, 1],

    DOCUMENT_UPLOAD: [1, 0],

    COMPLETION_CONFIRMATION: [2, 0],

    NEXT_STEPS: [3, 0],
};
