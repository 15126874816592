import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import { FormItem, TextField } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Context } from '../wizard/context';

export const INVALID_ELECTRICITY_METER_ID_ERROR_MESSAGE =
    'Bitte geben Sie eine Kombination aus Zahlen und/oder Buchstaben zwischen 4 und 14 Stellen ein.';

export const validateElectricityMeterSerialNumberSyntax = (electricityMeterId = '') => {
    const electricityMeterIdPattern = /^[a-z0-9]{4,14}$/i;
    if (!electricityMeterId || !electricityMeterIdPattern.test(electricityMeterId)) {
        return INVALID_ELECTRICITY_METER_ID_ERROR_MESSAGE;
    }
    return undefined;
};

export const ElectricityMeterSerialNumberField = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext<Context>();
    return (
        <FormItem label="Bitte geben Sie die Zählernummer an:" className="max-w-[32rem]">
            <div className="mb-4">
                <TextField
                    data-testid="electricity-meter-serial-number"
                    displayErrorMessage={errors?.electricityMeterSerialNumber?.message}
                    {...register('electricityMeterSerialNumber', {
                        required: REQUIRED_ERROR_MESSAGE,
                        validate: (value) => validateElectricityMeterSerialNumberSyntax(value),
                        shouldUnregister: true,
                    })}
                />
            </div>
        </FormItem>
    );
};
