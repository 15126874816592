import useApi from '@/hooks/useApi';
import { DASHBOARD } from '@/routes';
import { APIRequestState } from '@/types/Api';
import useConnectionAssurance from '@/utils/useConnectionAssurance';
import { CenteredLoadingSpinner, ErrorBox, createProcessGuidanceButtons } from '@ten-netzkundenportal/ui-components';
import {
    InstallerFilterResultWithDecodedCompanyName,
    InstallerSelectionForm,
} from '@ten-netzkundenportal/ui-installer-selection';
import * as React from 'react';

import updateConnectionAssuranceInstaller from './api/updateConnectionAssuranceInstaller';

export type ConnectionAssuranceInstallerSelectionProps = {
    connectionAssuranceId: string;
};
const ConnectionAssuranceInstallerSelection = ({
    connectionAssuranceId,
}: ConnectionAssuranceInstallerSelectionProps) => {
    const [chosenInstallers, setChosenInstallers] = React.useState<InstallerFilterResultWithDecodedCompanyName[]>([]);
    const [updateInstallerApiState, setUpdateInstallerApiState] = React.useState<APIRequestState>('initial');

    const updateConnectionAssuranceInstallerApi = useApi(updateConnectionAssuranceInstaller);

    const returnToDashboard = () => window.history.pushState({}, document.title, DASHBOARD);

    const { connectionAssurance, isLoading } = useConnectionAssurance(connectionAssuranceId);

    const onNext = async () => {
        if (chosenInstallers.length !== 1) {
            console.error('Exactly one installer should be selected.');
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { companyNameDecoded, ...installer } = chosenInstallers[0];
        setUpdateInstallerApiState('loading');
        try {
            await updateConnectionAssuranceInstallerApi({
                connectionAssuranceId,
                installer,
            });
            setUpdateInstallerApiState('finished_successfully');
            returnToDashboard();
        } catch (error) {
            setUpdateInstallerApiState('error');
        }
    };

    const buttons = createProcessGuidanceButtons({
        onBack: returnToDashboard,
        onNext,
        isNextValid: chosenInstallers.length > 0,
        nextLabel: 'Speichern',
        prevLabel: 'Abbrechen',
        loading: updateInstallerApiState === 'loading',
    });

    if (isLoading) {
        return <CenteredLoadingSpinner />;
    }

    return (
        <div className="relative">
            {connectionAssurance && (
                <InstallerSelectionForm
                    electricity
                    gas={false}
                    chosenInstallers={chosenInstallers}
                    setChosenInstallers={setChosenInstallers}
                    isElectricityModifiable={false}
                    isGasModifiable={false}
                    displayProps={{ showCardBranch: false, showFilterBranch: false }}
                    texts={{
                        description:
                            'Für die Umsetzung der Dienstleistung ist es erforderlich, einen Installateur ' +
                            'einzubinden. Bitte wählen Sie dafür den von Ihnen beauftragten Installateur aus.',
                        hint: undefined,
                    }}
                    installerId={connectionAssurance.chosenInstaller?.id}
                    buttons={buttons}
                    sortType="onlyAlphabetic"
                />
            )}
            {updateInstallerApiState === 'error' && (
                <div className="absolute left-0 bottom-10">
                    <ErrorBox onClick={() => setUpdateInstallerApiState('initial')} />
                </div>
            )}
        </div>
    );
};

export default ConnectionAssuranceInstallerSelection;
