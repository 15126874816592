import { CompensationDocumentsFileCategory } from '@/types/FileUpload';
import { UploadFileCategory } from '@ten-netzkundenportal/ui-document-upload';

import getContentPerFileCategory from './getContentPerFileCategory';

type Option = {
    label: string;
    value: CompensationDocumentsFileCategory;
};

export function getCompensationDocumentsFileCategories(): UploadFileCategory[] {
    const options: Option[] = [
        { label: 'Verbindliche Erklärung', value: 'bindingDeclaration' },
        { label: 'Erklärung Erhalt USt.', value: 'declarationOfReceiptOfVAT' },
        { label: 'Sonstige', value: 'compensationOther' },
    ];

    return options
        .filter((option) => !!option)
        .map((option) => ({
            ...option,
            createDescription: () => getContentPerFileCategory(option.value),
            tags: { category: 'compensationDocuments', subCategory: option.value },
        }));
}
