import MapComponent from '@/components/map/MapComponent';
import { ConnectionAssurance } from '@/types/ConnectionAssurance';
import { getCadastralDistrictLatLngArrayForConnectionAssurance } from '@/utils/getCadastralDistrictLatLngArray';
import useDrawConnectionDataProperties from '@components/map/hooks/useDrawConnectionDataProperties';
import L from 'leaflet';
import React from 'react';

type Props = {
    process: ConnectionAssurance;
};

const ConnectionAssuranceConnectionPlanFormView = ({ process }: Props) => {
    const [map, setMap] = React.useState<L.Map>(null);
    const cadastralDistrictLatLng =
        process.numberOfLocation === 'one'
            ? getCadastralDistrictLatLngArrayForConnectionAssurance(process.location)
            : undefined;

    const { isDrawing } = useDrawConnectionDataProperties(process, map);

    return (
        isDrawing &&
        cadastralDistrictLatLng && (
            <MapComponent cadastralDistrictLatLng={cadastralDistrictLatLng} map={map} setMap={setMap} isPowerSelected />
        )
    );
};

export default ConnectionAssuranceConnectionPlanFormView;
