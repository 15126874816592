import React from 'react';

export interface LazyPreload<Props> extends React.LazyExoticComponent<React.ComponentType<Props>> {
    preload: () => void;
}

const ReactLazyPreload = (importStatement) => {
    const Component = React.lazy(importStatement) as LazyPreload<never>;
    Component.preload = importStatement;
    return Component;
};

export default ReactLazyPreload;
