import { PersistedDocumentResponse } from '@/types/Document';
import { Button, CenteredLoadingSpinner } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import DocumentListArea from './DocumentListArea';
import DocumentUploadEntry from './DocumentUploadEntry';

interface ConnectionAssuranceDocumentsViewProps {
    isLoading: boolean;
    documentResponse: PersistedDocumentResponse;
    loadDocument: (documentId: string) => Promise<{ blob: Blob; type: string }>;
    goBack: () => void;
    uploadDocumentsPath: string;
}

const DocumentsView = ({
    isLoading,
    documentResponse,
    loadDocument,
    goBack,
    uploadDocumentsPath,
}: ConnectionAssuranceDocumentsViewProps): React.ReactElement => {
    documentResponse?.documents?.sort((a, b) => a.filename.localeCompare(b.filename));

    if (isLoading) {
        return <CenteredLoadingSpinner />;
    }

    return (
        <div className="w-full flex flex-col gap-y-12 py-10">
            {documentResponse ? (
                <>
                    <h3 className="font-bold text-medium" data-testid="headline">
                        Dokumentenverwaltung ({documentResponse.processCommunicationId})
                    </h3>
                    {documentResponse.documentUploadPossible && (
                        <DocumentUploadEntry uploadDocumentsPath={uploadDocumentsPath} />
                    )}
                    <DocumentListArea
                        documentList={documentResponse.documents}
                        documentsLoadingError={documentResponse.documentsLoadingError}
                        loadDocument={loadDocument}
                        documentUploadPossible={documentResponse.documentUploadPossible}
                    />
                </>
            ) : (
                <p>
                    Leider ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt
                    erneut.
                </p>
            )}

            <Button className="mt-8" label="Zurück" size="md" type="secondary" id="goBack" onClick={goBack} />
        </div>
    );
};

export default DocumentsView;
