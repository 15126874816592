import appConfig from '@/app.config';
import { ApiParams } from '@/hooks/useApi';

type UploadAdditionalDocumentsParams = {
    connectionAssuranceId: string;
    folderName: string;
    privacyPolicyAccepted: boolean;
};

export async function uploadAdditionalDocuments({
    axios,
    params,
    customerId,
}: ApiParams<UploadAdditionalDocumentsParams>): Promise<void> {
    await axios.post(
        `${appConfig.services.contractApi}/account/${customerId}/connection-assurance/${params.connectionAssuranceId}/document`,
        {
            folderName: params.folderName,
            privacyPolicyAccepted: params.privacyPolicyAccepted,
        },
    );
}
