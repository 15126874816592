import { isSaxony } from '@/utils/guards';
import { Column, ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { ConnectionAssuranceServiceType, OfflineCadastralDistrictLocation, OfflineLocationType } from '../../types';
import {
    ConnectionAssuranceLocation,
    NumberOfLocation,
    isAddress,
    isOfflineAddress,
    isOfflineSaxonyAnhaltOrThuringiaCadastralDistrict,
    isOfflineSaxonyCadastralDistrict,
} from '../../types/ConnectionAssuranceLocation';
import { PlantSize, plantSizeWithLabel } from '../../types/PlantSize';
import {
    formatConnectionAssuranceAddress,
    formatConnectionAssuranceCadastralDistrict,
    formatConnectionAssuranceServiceType,
} from '../../utils/formatting';

interface ServiceColumnProps {
    service: ConnectionAssuranceServiceType;
    numberOfLocation: NumberOfLocation;
    location: ConnectionAssuranceLocation;
    oneOfManyLocations?: OfflineLocationType;
    plantSize: PlantSize;
}

const getPlantLocationLabel = (numberOfLocation: NumberOfLocation, location: ConnectionAssuranceLocation) => {
    if (numberOfLocation === 'many') {
        return 'Hauptstandort der Erzeugungsanlage';
    }
    return isAddress(location) ? 'Adresse' : 'Flurstück';
};

const getCadastralDenominator = (oneOfManyLocations: OfflineCadastralDistrictLocation) => {
    if (oneOfManyLocations.cadastralUnitDenominator) return `/${oneOfManyLocations.cadastralUnitDenominator}`;
    return '';
};

const getPlantLocationText = (location: ConnectionAssuranceLocation, oneOfManyLocations: OfflineLocationType) => {
    if (location) {
        return isAddress(location)
            ? formatConnectionAssuranceAddress(location)
            : formatConnectionAssuranceCadastralDistrict(location);
    }

    if (isOfflineAddress(oneOfManyLocations)) {
        return [
            `${oneOfManyLocations.street} ${oneOfManyLocations.houseNumber}`,
            `${oneOfManyLocations.postalCode} ${oneOfManyLocations.city}`,
        ];
    }
    if (isOfflineSaxonyAnhaltOrThuringiaCadastralDistrict(oneOfManyLocations)) {
        if (!isSaxony(oneOfManyLocations.stateCode)) {
            return `${oneOfManyLocations.districtName}, ${oneOfManyLocations.cadastralDistrict}, 
                 ${oneOfManyLocations.cadastralUnitCounter}${getCadastralDenominator(oneOfManyLocations)}`;
        }
    }
    if (isOfflineSaxonyCadastralDistrict(oneOfManyLocations)) {
        return `${oneOfManyLocations.districtName}, 
            ${oneOfManyLocations.cadastralUnitCounter}${getCadastralDenominator(oneOfManyLocations)}`;
    }

    return [];
};

const ServiceColumn = ({
    service,
    numberOfLocation,
    location,
    plantSize,
    oneOfManyLocations,
}: ServiceColumnProps): React.ReactElement => {
    const plantSizeLabel = plantSizeWithLabel.find((element) => element.value === plantSize)?.label;

    return (
        <div className="col-span-1">
            <Column title="Service">
                <ColumnSection title="Serviceauswahl">
                    <ColumnItem label="Service" text={formatConnectionAssuranceServiceType(service)} />
                    <ColumnItem label="Anlagengröße" text={plantSizeLabel} />
                </ColumnSection>
                <ColumnSection title="Standort der Erzeugungsanlage">
                    <ColumnItem
                        {...{
                            label: getPlantLocationLabel(numberOfLocation, location),
                            text: getPlantLocationText(location, oneOfManyLocations),
                        }}
                    />
                </ColumnSection>
            </Column>
        </div>
    );
};

export default ServiceColumn;
