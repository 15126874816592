import { ShapeType, SolarModuleArea } from '@/types/ConnectionDataProperties';
import L from 'leaflet';

export type DrawnSolarModuleArea = { key: number; value: SolarModuleArea };

export type Params = {
    solarModuleAreaList: DrawnSolarModuleArea[];
    map: L.Map;
    pathOptions?: {
        color: string;
        fillColor: string;
        weight: number;
    };
};

type LayerMetadata = {
    layer: L.Polygon;
    shapeType: ShapeType;
    key: number;
};

const drawSolarModuleArea = (
    solarModuleAreaList: DrawnSolarModuleArea[],
    map: L.Map,
    pathOptions = {
        color: 'rgb(13, 103, 157)',
        fillColor: 'rgb(13, 103, 157)',
        weight: 2,
    },
) => {
    let layers: LayerMetadata[] = [];

    if (solarModuleAreaList && map) {
        const tempLayers: LayerMetadata[] = [];
        solarModuleAreaList?.forEach((shape) => {
            const { latLngs, shapeType, angle } = shape.value;

            const tempLayer =
                shapeType === 'Rectangle'
                    ? L.rectangle(L.polygon(latLngs).getBounds(), pathOptions).setLatLngs(latLngs)
                    : L.polygon(latLngs, pathOptions);
            tempLayer.pm.setOptions({ removeLayerBelowMinVertexCount: false });
            map.addLayer(tempLayer);
            if (shapeType === 'Rectangle' && angle) {
                // set the angle property so editing loaded rectangles does not reset their rotation
                // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/dot-notation
                tempLayer.pm['_angle'] = angle;
            }
            tempLayers.push({
                layer: tempLayer,
                key: shape.key,
                shapeType,
            });
        });
        layers = tempLayers;
    }

    return layers;
};

export default drawSolarModuleArea;
