import { DASHBOARD } from '@/routes';
import { CenteredLoadingSpinner } from '@ten-netzkundenportal/ui-components';
import React, { useEffect } from 'react';
import { navigateToUrl } from 'single-spa';

export const RedirectToDashboard = () => {
    useEffect(() => {
        navigateToUrl(DASHBOARD);
    }, []);

    return <CenteredLoadingSpinner />;
};
