import appConfig from '@/app.config';
import { CommissioningDataRequest } from '@/connection-assurance/commissioning/types/CommissioningData';
import { ApiParams } from '@/hooks/useApi';

type PatchConnectionAssuranceCommissioningParams = {
    connectionAssuranceId: string;
    commissioningData: CommissioningDataRequest;
};

export async function patchConnectionAssuranceCommissioning({
    axios,
    params,
    installerId,
}: ApiParams<PatchConnectionAssuranceCommissioningParams>): Promise<void> {
    await axios.patch(
        `${appConfig.services.contractApi}/installer/${installerId}/connection-assurance/${params.connectionAssuranceId}/commissioning`,
        params.commissioningData,
    );
}
