import { FurthestStep } from '@/types';

import { ALL_STEPS, SUBPROCESS_INDEX_MAPPING, SubprocessValue } from './constants';

// To satisfy wizards requirements, subprocess should start with 1.
// The index 0 is reserved only for active Process with inactive subprocess.
export const getFurthestSteps = (process: SubprocessValue): FurthestStep => {
    const processAndSubProcessIndex = SUBPROCESS_INDEX_MAPPING[process];
    if (processAndSubProcessIndex === undefined) {
        throw Error(`No Process is found with the given parameter ${process}`);
    }

    const [processIndex, subProcessIndex] = processAndSubProcessIndex;

    const processIndexName = ALL_STEPS[processIndex];
    return {
        furthestStep: {
            furthestProcess: processIndexName,
            furthestSubprocess: processIndexName === 'NEXT_STEPS' ? subProcessIndex : subProcessIndex + 1,
        },
    };
};
