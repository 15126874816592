import { GeneratorData } from '@/types/GeneratorData';
import { Column, ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { ConnectionAssuranceLocation, NumberOfLocation } from '../../types/ConnectionAssuranceLocation';
import { ConnectionDataProperties } from '../../types/ConnectionDataProperties';
import { PlantSize } from '../../types/PlantSize';

interface ConnectionColumnProps {
    connectionData: ConnectionDataProperties;
    plantSize: PlantSize;
    numberOfLocation: NumberOfLocation;
    location: ConnectionAssuranceLocation;
    generatorData: GeneratorData;
}

const isConnectionPlanPageShown = (
    plantSize: PlantSize,
    numberOfLocation: NumberOfLocation,
    location: ConnectionAssuranceLocation,
): boolean => {
    if (numberOfLocation === undefined) {
        return true;
    }
    if (numberOfLocation === 'many' || plantSize === 'big') {
        return false;
    }

    if (location && location.responsibilityAndAvailability) {
        const { lowVoltageResponsibility, mediumVoltageAvailability } = location.responsibilityAndAvailability;

        if (
            lowVoltageResponsibility === 'Other' &&
            (mediumVoltageAvailability === 'LineAvailable' || mediumVoltageAvailability === 'OperatingSupply')
        ) {
            return false;
        }
    }

    return true;
};

const isShowElectricityMeterSection = (connectionData: ConnectionDataProperties) =>
    !!connectionData.electricityMeterSerialNumber;

const isElectricityMeterPageShown = (
    plantSize: PlantSize,
    numberOfLocation: NumberOfLocation,
    location: ConnectionAssuranceLocation,
    connectionData: ConnectionDataProperties,
    generatorData: GeneratorData,
): boolean => {
    if (!generatorData?.surplusPowerFeedIn && !isShowElectricityMeterSection(connectionData)) {
        return false;
    }

    if (!isConnectionPlanPageShown(plantSize, numberOfLocation, location)) {
        return true;
    }

    if (connectionData.electricityMarker && connectionData.electricityConnectionPointType !== 'contracted') {
        return false;
    }

    return true;
};

const existingConnectionText = (connectionData: ConnectionDataProperties) => {
    const { electricityConnectionPointType } = connectionData;

    return electricityConnectionPointType === 'contracted' || electricityConnectionPointType === 'planned'
        ? 'ja'
        : 'nein, Anschluss neu gesetzt';
};

const ConnectionColumn = ({
    connectionData,
    plantSize,
    numberOfLocation,
    location,
    generatorData,
}: ConnectionColumnProps): React.ReactElement => (
    <div className="col-span-1">
        <Column title="Anschluss">
            {isConnectionPlanPageShown(plantSize, numberOfLocation, location) && (
                <ColumnSection title="Anlagenplan">
                    {connectionData.electricityMarker ? (
                        <ColumnItem label="Bestehender Anschluss" text={existingConnectionText(connectionData)} />
                    ) : (
                        <>
                            <ColumnItem label="Bestehender Anschluss" />
                            <p className="italic -mt-4">keine Angabe</p>
                        </>
                    )}

                    {connectionData.connecteeConsent && !connectionData.plantOperatorIsConnectee && (
                        <ColumnItem label="Einverständnis des Anschlussnehmer" text="ja, liegt vor" />
                    )}
                </ColumnSection>
            )}
            {isElectricityMeterPageShown(plantSize, numberOfLocation, location, connectionData, generatorData) && (
                <ColumnSection title="Zähler">
                    {isShowElectricityMeterSection(connectionData) ? (
                        <ColumnItem label="Zählernummer" text={connectionData.electricityMeterSerialNumber} />
                    ) : (
                        <p className="italic">keine Angabe</p>
                    )}
                </ColumnSection>
            )}
        </Column>
    </div>
);

export default ConnectionColumn;
