import { ConnectionAssuranceCommissioningRouting } from '@/connection-assurance/commissioning/ConnectionAssuranceCommissioningRouting';
import { DASHBOARD } from '@/routes';
import { PlantComponent, PlantComponentDetailsEntry, PlantComponentManufacturer } from '@/types/PlantComponent';
import { PlantSize } from '@/types/PlantSize';
import { plantSizeIsAtMostOfSmallSize } from '@/utils/plantSize';
import useConnectionAssurance from '@/utils/useConnectionAssurance';
import { Button, LoadingSpinner } from '@ten-netzkundenportal/ui-components';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { getPlantComponents } from './api/getPlantComponents';

export const mapManufacturerToPlantComponent = (
    plantComponentManufacturerEntry: PlantComponentManufacturer,
    plantComponent: PlantComponentDetailsEntry,
): PlantComponent => ({
    manufacturerName: plantComponentManufacturerEntry.manufacturer,
    manufacturerId: plantComponentManufacturerEntry.id,
    activePower: plantComponent.activePower,
    apparentPower: plantComponent.apparentPower,
    plantComponentName: plantComponent.description,
    plantComponentId: plantComponent.id,
    plantType: plantComponent.plantType,
    inverterPhaseType: plantComponent.inverterPhaseType,
    quantity: 0,
    isolatedOperation: false,
});

function preProcessedPlantComponents(plantComponentsData: PlantComponentManufacturer[], plantSize: PlantSize) {
    const components = plantComponentsData
        .map((plantComponentsManufacturerEntry) =>
            plantComponentsManufacturerEntry.plantComponents.map((plantComponent) =>
                mapManufacturerToPlantComponent(plantComponentsManufacturerEntry, plantComponent),
            ),
        )
        .flat()
        .sort((a, b) => a.plantComponentName.localeCompare(b.plantComponentName));

    if (plantSizeIsAtMostOfSmallSize({ plantSize })) {
        return components.filter((component) => component.activePower <= 50);
    }
    return components;
}

export const ConnectionAssuranceCommissioningRoot = () => {
    const { connectionAssuranceId } = useParams<{ connectionAssuranceId: string }>();
    const { isLoading, connectionAssurance } = useConnectionAssurance(connectionAssuranceId);
    const [allPlantComponents, setAllPlantComponents] = useState<PlantComponent[]>([]);
    const [isPlantComponentsLoading, setIsPlantComponentsLoading] = useState(false);

    React.useEffect(() => {
        setIsPlantComponentsLoading(true);
        getPlantComponents()
            .then((plantComponentManufacturer) => {
                const processedPlantComponent = preProcessedPlantComponents(
                    plantComponentManufacturer,
                    connectionAssurance?.plantSize,
                );
                setAllPlantComponents(processedPlantComponent);
            })
            .finally(() => setIsPlantComponentsLoading(false));
    }, [connectionAssurance?.plantSize]);

    if (isLoading || isPlantComponentsLoading) {
        return (
            <div className="h-full grid md:grid-rows-5">
                <div className="w-20 h-20 row-start-3 justify-self-center">
                    <LoadingSpinner />
                </div>
            </div>
        );
    }

    if (!connectionAssurance) {
        return (
            <div className="w-full form flex flex-col gap-y-6 sm:gap-y-12 py-8 sm:py-10 relative">
                <div className="flex flex-col gap-y-4">
                    Leider ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt
                    erneut.
                </div>

                <div className="grid grid-flow-col mt-10 min-h-min">
                    <div className="button place-self-end flex">
                        <Button
                            label="Zur &Uuml;bersicht"
                            onClick={() => {
                                window.history.pushState({}, document.title, DASHBOARD);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <ConnectionAssuranceCommissioningRouting
            connectionAssurance={connectionAssurance}
            allPlantComponents={allPlantComponents}
        />
    );
};
