import { NetworkCompatibilityAssessmentDocumentsFileCategory } from '@/types/FileUpload';
import { InfoI } from '@ten-netzkundenportal/ui-components';
import React, { MutableRefObject } from 'react';

export default (
    fileCategory: NetworkCompatibilityAssessmentDocumentsFileCategory,
    boundaryDefiningParentRef: MutableRefObject<HTMLElement>,
) => {
    switch (fileCategory) {
        case 'sitePlan':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Maßstabsgerechter Lageplan, aus dem die Standorte der Erzeugungsanlagen hervorgehen.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        default:
            return undefined;
    }
};
