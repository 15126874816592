import appConfig from '@/app.config';
import { PlantComponentManufacturer, PlantType } from '@/types/PlantComponent';
import axios, { AxiosResponse } from 'axios';

type Params = {
    plantTypes: PlantType[];
};

export const getPlantComponents = async (params?: Params): Promise<PlantComponentManufacturer[]> => {
    const response: AxiosResponse<PlantComponentManufacturer[]> = await axios.get(
        `${appConfig.services.contractApi}/plant-component-manufacturer`,
        {
            params: {
                plantTypes: params?.plantTypes?.toString() ?? undefined,
            },
        },
    );

    return response.data;
};
