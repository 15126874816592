import {
    ConnectionAssuranceAddressLocation,
    ConnectionAssuranceCadastralDistrictLocation,
} from '@/types/ConnectionAssuranceLocation';
import { isAddressConnectionAssurance } from '@/utils/guards';
import L from 'leaflet';

export const getCadastralDistrictLatLngArrayForConnectionAssurance = (
    contextLocation: ConnectionAssuranceAddressLocation | ConnectionAssuranceCadastralDistrictLocation,
) => {
    const isItAddress = isAddressConnectionAssurance(contextLocation);
    const polygonCadastralDistricts = !isItAddress
        ? contextLocation.geojson.coordinates
        : contextLocation.cadastralDistrictCoordinates.coordinates;
    return polygonCadastralDistricts.map((coordinateArray) =>
        coordinateArray.map((coordinates) => L.latLng(coordinates[1], coordinates[0])),
    )[0];
};

export const getCadastralDistrictLatLngArrayForContract = (contextLocation: {
    generalCadastralDistrictCoordinates: number[][][];
}) =>
    contextLocation.generalCadastralDistrictCoordinates.map((coordinateArray) =>
        coordinateArray.map((coordinates) => L.latLng(coordinates[1], coordinates[0])),
    )[0];
