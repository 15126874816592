import { ConnectionRelevantDocumentFileCategory } from '@/types/FileUpload';
import { PlantComponent } from '@/types/PlantComponent';
import { PlantSize } from '@/types/PlantSize';
import { calculateSumOfActivePowersInKW } from '@/utils/plantComponents';
import { MutableRefObject } from 'react';

import getContentPerFileCategory from './getContentPerFileCategory';

type Option = { label: string; value: ConnectionRelevantDocumentFileCategory; required?: boolean };

const systemActivePowerLimit = 135;

export function getConnectionRelevantDocumentsFileCategories(
    plantSize: PlantSize,
    plantComponents: PlantComponent[],
    showRequiredDocumentsSection: boolean,
) {
    const requestIsMediumCapacityOrBigger = plantSize === 'big' || plantSize === 'medium';

    const isNewPlantComponentAdded = plantComponents.some((plantComponent) => !plantComponent.plantComponentId);
    const hasPlantComponentWithIsolatedOperation = plantComponents.some((p) => p.isolatedOperation);
    const totalActivePower = calculateSumOfActivePowersInKW(plantComponents);

    const options: Option[] = [
        {
            label: 'Anmeldung Netzanschluss',
            value: 'connectionRegistration',
        },
        {
            label: 'Übersichtsschaltplan',
            value: 'wiringOverviewDiagram',
        },
        requestIsMediumCapacityOrBigger
            ? {
                  label: 'Auftrag zur Bereitstellung von Mess- und Verrechnungswandlern',
                  value: 'provisionOrderForMeasuringAndBillingTransformator',
              }
            : undefined,
        isNewPlantComponentAdded
            ? {
                  label: 'Technisches Datenblatt',
                  value: 'technicalDatasheet',
                  required: showRequiredDocumentsSection && isNewPlantComponentAdded,
              }
            : undefined,
        isNewPlantComponentAdded
            ? {
                  label: 'Einheitenzertifikat',
                  value: 'unitsCertificate',
                  required: showRequiredDocumentsSection && isNewPlantComponentAdded,
              }
            : undefined,
        isNewPlantComponentAdded
            ? {
                  label: 'Zertifikat Netz- und Anlagenschutz',
                  value: 'connectionAndPlantProtectionCertificate',
                  required: showRequiredDocumentsSection && isNewPlantComponentAdded,
              }
            : undefined,
        totalActivePower >= systemActivePowerLimit
            ? {
                  label: 'Datenblatt einer Erzeugungsanlage/eines Speichers aus der VDE-AR-N 4110 bzw. 4120 ',
                  value: 'datasheetVDE-AR-N4110/4120',
              }
            : undefined,
        plantSize === 'big'
            ? {
                  label: 'Lageplan der Anschlussnehmerstation oder des Umspannwerks',
                  value: 'stationSitePlan',
              }
            : undefined,
        plantSize === 'big'
            ? {
                  label: 'Projektunterlagen der Anschlussnehmerstation oder des Umspannwerks',
                  value: 'stationProjectDocuments',
              }
            : undefined,
        plantSize === 'big'
            ? {
                  label: 'Prüfbericht des Transformators',
                  value: 'transformerTestReport',
              }
            : undefined,
        requestIsMediumCapacityOrBigger
            ? {
                  label: 'Baugenehmigung und/oder eine Anlagengenehmigung',
                  value: 'buildingOrPlantPermit',
              }
            : undefined,
        hasPlantComponentWithIsolatedOperation
            ? {
                  label: 'Inselbetrieb',
                  value: 'isolatedOperation',
              }
            : undefined,
        totalActivePower >= systemActivePowerLimit
            ? {
                  label: 'Anlagenzertifikat',
                  value: 'plantCertificate',
              }
            : undefined,
    ];

    return options
        .filter((option) => !!option)
        .map((option) => ({
            ...option,
            createDescription: (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategory(option.value, boundaryDefiningParentRef),
            tags: { category: 'connectionRelevantDocuments', subCategory: option.value },
        }));
}
