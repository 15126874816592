import appConfig from '@/app.config';
import { useMsal } from '@azure/msal-react';
import { acquireAccessToken, loginRequest } from '@ten-netzkundenportal/ui-auth-utils';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import React from 'react';

const useAuthenticatedHttpClient = (): AxiosInstance => {
    const { instance, accounts } = useMsal();

    return React.useMemo(() => {
        const httpClient = axios.create();
        httpClient.interceptors.request.use(async (config): Promise<InternalAxiosRequestConfig> => {
            const token = await acquireAccessToken(instance, accounts[0], loginRequest(appConfig).scopes);
            const headers = { ...config.headers, Authorization: `Bearer ${token}` };
            return { ...config, headers } as InternalAxiosRequestConfig;
        });

        return httpClient;
    }, [accounts, instance]);
};

export default useAuthenticatedHttpClient;
