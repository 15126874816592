import { CompensationDocumentsFileCategory } from '@/types/FileUpload';
import PrintOutLink from '@components/documentUpload/documentUploadForm/PrintOutLink';
import { StyledLink } from '@ten-netzkundenportal/ui-components';
import React from 'react';

export default (fileCategory: CompensationDocumentsFileCategory) => {
    switch (fileCategory) {
        case 'bindingDeclaration':
            return (
                <>
                    <br />
                    (hier finden Sie eine Übersicht der{' '}
                    <StyledLink
                        href="https://www.thueringer-energienetze.com/Einspeisung/Stromnetz/Erzeugungsanlagen_ab_30kW_Formulare"
                        target="_blank"
                    >
                        Vordrucke
                    </StyledLink>
                    )
                </>
            );
        case 'declarationOfReceiptOfVAT':
            return (
                <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/TEN-AR4-5_Erklaerung_ueber_Berechtigung_Erhalt_UST_SEPA.pdf" />
            );

        default:
            return undefined;
    }
};
