import React from 'react';

type Props = {
    children: React.ReactNode;
    className?: string;
};

export const TableTitle = ({ children, className }: Props) => (
    <span className={`bg-white border-b border-darkest-grey py-5 ${className}`}>{children}</span>
);
