import { getLocalStorageEntryName } from '@/connection-assurance/commissioning/wizard/wizard';
import {
    CONNECTION_ASSURANCE_COMMISSIONING_COMPLETION_CONFIRMATION,
    CONNECTION_ASSURANCE_COMMISSIONING_DOCUMENT_UPLOAD,
    CONNECTION_ASSURANCE_COMMISSIONING_NEXT_STEPS,
    CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_GENERATOR,
    CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_STORAGE,
} from '@/routes';
import { generatePath } from 'react-router-dom';
import { MachineConfig } from 'xstate';

import { STEPS, SUBPROCESS_INDEX_MAPPING } from './constants';
import { Context } from './context';
import {
    SYNC_LOCATION_WITH_STATE,
    SYNC_STATE_WITH_LOCATION,
    UPDATE_CONTEXT,
    UPDATE_FURTHEST_STEPS,
    UPDATE_PATH,
    UserEvent,
} from './events';
import guards from './guards';

const initial = 'initial';

// To satisfy wizards requirements, subprocess should start with 1.
// The index 0 is reserved only for active Process with inactive subprocess.

const getStates = (connectionAssuranceId: string) => ({
    initial: {
        always: [{ target: 'generationPlant' }],
    },
    generationPlant: {
        initial,
        states: {
            initial: {
                always: [
                    { target: 'plantComponentsGenerator', cond: guards.isPlantComponentsGeneratorShown },
                    { target: 'plantComponentsStorage', cond: guards.isPlantComponentsStorageShown },
                ],
            },
            plantComponentsGenerator: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.GENERATION_PLANT,
                        subprocess: SUBPROCESS_INDEX_MAPPING.PLANT_COMPONENTS_GENERATOR[1] + 1,
                    },
                    {
                        type: UPDATE_PATH,
                        path: generatePath(CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_GENERATOR, {
                            connectionAssuranceId,
                        }),
                    },
                ],
                meta: {
                    path: generatePath(CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_GENERATOR, {
                        connectionAssuranceId,
                    }),
                },
                on: {
                    NEXT: { target: 'plantComponentsStorage', cond: guards.isPlantComponentsStorageShown },
                },
            },
            plantComponentsStorage: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.GENERATION_PLANT,
                        subprocess: SUBPROCESS_INDEX_MAPPING.PLANT_COMPONENTS_STORAGE[1] + 1,
                    },
                    {
                        type: UPDATE_PATH,
                        path: generatePath(CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_STORAGE, {
                            connectionAssuranceId,
                        }),
                    },
                ],
                meta: {
                    path: generatePath(CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_STORAGE, {
                        connectionAssuranceId,
                    }),
                },
                on: {
                    PREV: { target: 'plantComponentsGenerator' },
                },
            },
            tail: {
                always: [
                    { target: 'plantComponentsStorage', cond: guards.isPlantComponentsStorageShown },
                    { target: 'plantComponentsGenerator' },
                ],
            },
        },
        on: {
            NEXT: { target: 'documents' },
        },
    },

    documents: {
        initial,
        states: {
            initial: {
                always: { target: 'documentUpload' },
            },
            documentUpload: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.DOCUMENT_UPLOAD,
                        subprocess: SUBPROCESS_INDEX_MAPPING.DOCUMENT_UPLOAD[1] + 1,
                    },
                    {
                        type: UPDATE_PATH,
                        path: generatePath(CONNECTION_ASSURANCE_COMMISSIONING_DOCUMENT_UPLOAD, {
                            connectionAssuranceId,
                        }),
                    },
                ],
                meta: {
                    path: generatePath(CONNECTION_ASSURANCE_COMMISSIONING_DOCUMENT_UPLOAD, {
                        connectionAssuranceId,
                    }),
                },
            },
            tail: {
                always: { target: 'documentUpload' },
            },
        },
        on: {
            NEXT: { target: 'completion' },
            PREV: { target: 'generationPlant.tail' },
        },
    },

    completion: {
        initial,
        states: {
            initial: {
                always: { target: 'completionConfirmation' },
            },
            completionConfirmation: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.COMPLETION_CONFIRMATION,
                        subprocess: SUBPROCESS_INDEX_MAPPING.COMPLETION_CONFIRMATION[1] + 1,
                    },
                    {
                        type: UPDATE_PATH,
                        path: generatePath(CONNECTION_ASSURANCE_COMMISSIONING_COMPLETION_CONFIRMATION, {
                            connectionAssuranceId,
                        }),
                    },
                ],
                meta: {
                    path: generatePath(CONNECTION_ASSURANCE_COMMISSIONING_COMPLETION_CONFIRMATION, {
                        connectionAssuranceId,
                    }),
                },
            },
            tail: {
                always: { target: 'completionConfirmation' },
            },
        },
        on: {
            NEXT: { target: 'nextSteps' },
            PREV: { target: 'documents.tail' },
        },
    },
    nextSteps: {
        entry: [
            {
                type: UPDATE_FURTHEST_STEPS,
                process: STEPS.NEXT_STEPS,
                subprocess: SUBPROCESS_INDEX_MAPPING.NEXT_STEPS[1],
            },
            {
                type: UPDATE_PATH,
                path: generatePath(CONNECTION_ASSURANCE_COMMISSIONING_NEXT_STEPS, {
                    connectionAssuranceId,
                }),
            },
        ],
        meta: {
            path: generatePath(CONNECTION_ASSURANCE_COMMISSIONING_NEXT_STEPS, {
                connectionAssuranceId,
            }),
        },
        on: {
            PREV: { target: 'completion.tail' },
        },
    },
});

export const getConfig = (connectionAssuranceId: string) =>
    ({
        id: getLocalStorageEntryName(connectionAssuranceId),
        initial,
        context: {},
        on: {
            UPDATE_CONTEXT: {
                actions: [UPDATE_CONTEXT],
            },
            SYNC_STATE_WITH_LOCATION: {
                actions: [SYNC_STATE_WITH_LOCATION],
            },
            SYNC_LOCATION_WITH_STATE: {
                actions: [SYNC_LOCATION_WITH_STATE],
            },
            UPDATE_PATH: {
                actions: [UPDATE_PATH],
            },
        },
        states: getStates(connectionAssuranceId),
        strict: true,
        predictableActionArguments: true,
    }) as MachineConfig<Partial<Context>, never, UserEvent>;
