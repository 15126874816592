import { NetworkSecurityManagementDocumentsFileCategory } from '@/types/FileUpload';
import { InfoI, StyledLink } from '@ten-netzkundenportal/ui-components';
import React, { MutableRefObject } from 'react';

import PrintOutLink from '../PrintOutLink';

export default (
    fileCategory: NetworkSecurityManagementDocumentsFileCategory,
    boundaryDefiningParentRef: MutableRefObject<HTMLElement>,
) => {
    switch (fileCategory) {
        case 'protocolFWAInstallerConfirmation':
            return (
                <>
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/td41_errichterbestaetigung_fernwirk_uw.pdf" />
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Protokoll/Errichterbestätigung für Prüfungen der Fernwirkankopplung des
                                    kundeneigenen Umspannwerks.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );
        case 'vpnRouterOrder':
            return (
                <>
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/td36_auftrag_param_vpn_router.pdf" />
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Auftrag zur Parametrierung eines VPN-Routers für die Fernwirkankopplung.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );

        case 'telecontrolCouplingOperationReadinessDeclaration':
            return (
                <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/td14_betriebsbereitschaftserkl_fernwirk.pdf" />
            );
        case 'radioRippleControlReceiverOrder':
            return (
                <>
                    <br />
                    (hier finden Sie den Vordruck für die{' '}
                    <StyledLink
                        target="_blank"
                        href="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/td35_auftrag_parametr_frste.pdf"
                    >
                        Parametrierung
                    </StyledLink>{' '}
                    und für die{' '}
                    <StyledLink
                        href="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/td42_auftrag_lieferung_frste.pdf"
                        target="_blank"
                    >
                        Lieferung
                    </StyledLink>
                    )
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Auftrag Parametrierung von Funkrundsteuerempfängern oder Auftrag Lieferung und
                                    Parametrierung eines Funkrundsteuerempfängers durch den Netzbetreiber.
                                    <br />
                                    <br />
                                    Eine Pflicht zum Einbau eines Fundrundsteuerempfängers besteht nicht für Anlagen
                                    unter 25kW / kWp.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );
        case 'radioRippleControlReceiverCommissioningOrShutdown':
            return (
                <>
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/td31_inbetriebnahme_frste.pdf" />
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={
                                <span className="hyphens-auto">
                                    Eine Pflicht zum Einbau eines Fundrundsteuerempfängers besteht nicht für Anlagen
                                    unter 25kW / kWp.
                                </span>
                            }
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );

        default:
            return undefined;
    }
};
