import appConfig from '@/app.config';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as React from 'react';
import { MapContainer, Polyline, TileLayer, WMSTileLayer, ZoomControl } from 'react-leaflet';

import '@geoman-io/leaflet-geoman-free';

type MapComponentProps = {
    map: L.Map;
    setMap: React.Dispatch<React.SetStateAction<L.Map>>;
    children?: React.ReactNode;
    cadastralDistrictLatLng: L.LatLng[];
    isPowerSelected?: boolean;
    isGasSelected?: boolean;
};

const padBoundsByXMeters = (bounds: L.LatLngBounds, meters: number) =>
    bounds.extend(bounds.getNorthWest().toBounds(meters * 2)).extend(bounds.getSouthEast().toBounds(meters * 2));

const MapComponent = ({
    children,
    cadastralDistrictLatLng,
    map,
    setMap,
    isGasSelected = false,
    isPowerSelected = false,
}: MapComponentProps) => {
    const [isControlLayerSet, setIsControlLayerSet] = React.useState<boolean>(false);

    const center = L.polygon(cadastralDistrictLatLng).getBounds().getCenter();
    const polyBoundingBox = L.polygon(cadastralDistrictLatLng).getBounds();

    const layersToSelect = ['CadastralDistrict', isPowerSelected && 'Power', isGasSelected && 'Gas']
        .filter((layer) => layer && layer.length > 0)
        .join(',');

    React.useEffect(() => {
        if (!map || isControlLayerSet) return;

        if (cadastralDistrictLatLng) {
            map.setMinZoom(map.getZoom());
            map.setMaxBounds(map.getBounds().pad(0.01));
        }
        setIsControlLayerSet(true);
    }, [isControlLayerSet, map, cadastralDistrictLatLng]);

    const wmsTileLayer = React.useMemo(
        () => (
            <WMSTileLayer
                url={`${appConfig.services.gisMiddlewareApi}/wms?code=${encodeURIComponent(appConfig.services.gisMiddlewareApiKey)}`}
                crs={L.CRS.EPSG4326}
                format="image/png"
                maxZoom={24}
                maxNativeZoom={22}
                opacity={0.9}
                transparent
                params={{
                    layers: layersToSelect,
                    version: '1.3.0',
                }}
            />
        ),
        [layersToSelect],
    );

    return (
        <div className="h-[600px] relative">
            <MapContainer
                center={center}
                zoomControl={false}
                maxZoom={24}
                className="w-full h-full"
                zoomSnap={0.01}
                zoomDelta={0.5}
                bounds={padBoundsByXMeters(polyBoundingBox, 25)}
                wheelPxPerZoomLevel={70}
                inertia={false}
                maxBoundsViscosity={1}
                ref={setMap}
            >
                <TileLayer
                    maxNativeZoom={19}
                    maxZoom={26}
                    opacity={0.6}
                    attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tile.openstreetmap.de/{z}/{x}/{y}.png"
                />
                {wmsTileLayer}
                {cadastralDistrictLatLng && (
                    <Polyline pathOptions={{ color: '#24abe6' }} positions={cadastralDistrictLatLng} pmIgnore />
                )}
                <ZoomControl position="bottomright" />
                <div
                    className="w-min h-8 bottom-4 left-4 gap-x-3 px-3 border-none box-border flex border
                        bg-white absolute items-center shadow rounded-lg cursor-auto"
                    style={{ zIndex: 800 }}
                >
                    {isPowerSelected && (
                        <div className="flex w-min place-items-center">
                            <span className="h-0 w-4 border-t-4 border-electricity-green" />
                            <span className="ml-2 text-electricity-green">Stromleitung</span>
                        </div>
                    )}
                    {isGasSelected && (
                        <div className="flex w-min place-items-center">
                            <span className="h-0 w-4 border-t-4 border-gas-yellow" />
                            <span className="ml-2 text-gas-yellow">Erdgasleitung</span>
                        </div>
                    )}
                </div>
                {children}
            </MapContainer>
        </div>
    );
};

export default MapComponent;
