import { PlantComponent } from '@/types/PlantComponent';
import Fuse from 'fuse.js';
import * as React from 'react';

export const usePlantComponentsSearch = (
    processedPlantComponents: PlantComponent[],
    searchValue: string,
    contextPlantComponentIds: number[],
) => {
    const search = React.useCallback(
        (term: string): PlantComponent[] => {
            if (!term) {
                return processedPlantComponents;
            }

            const fuse = new Fuse(processedPlantComponents, {
                keys: ['plantComponentName'],
                threshold: 0.4,
                shouldSort: true,
            });

            return fuse.search(term).map(({ item }) => item);
        },
        [processedPlantComponents],
    );

    return search(searchValue).filter(
        (plantComponent) => !contextPlantComponentIds.includes(plantComponent.plantComponentId),
    );
};
