import { PlantComponent, PlantComponentType } from '@/types/PlantComponent';
import { UpdateContextFunction } from '@/types/UpdateContextFunction';
import { formatNumber } from '@/utils/formatNumber';
import { Checkbox, IconsCloseMenu, TextField } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export type PlantComponentEntryProps = {
    plantComponent: PlantComponent;
    contextPlantComponents: PlantComponent[];
    plantComponentType: PlantComponentType;
    isSelected?: boolean;
    updateContext: UpdateContextFunction;
    tableRef?: React.MutableRefObject<HTMLDivElement>;
};

export const PlantComponentEntry = ({
    plantComponent,
    contextPlantComponents,
    plantComponentType,
    isSelected = false,
    updateContext,
    tableRef,
}: PlantComponentEntryProps) => {
    const indexOfPlantComponent = React.useMemo(
        () =>
            contextPlantComponents.findIndex(
                (contextPlantComponent) => contextPlantComponent?.plantComponentId === plantComponent.plantComponentId,
            ),
        [contextPlantComponents, plantComponent],
    );

    const isPlantComponentInContext = indexOfPlantComponent !== -1;

    const isValidQuantity = (val: string | number) => Number.isInteger(+val) && +val > 0 && +val <= 9_999;

    const {
        register,
        formState: { errors },
        setValue,
        watch,
    } = useFormContext<{
        plantComponentsGenerator: PlantComponent[];
        plantComponentsStorage: PlantComponent[];
    }>();

    const quantity = watch(`${plantComponentType}.${plantComponent.plantComponentId}.quantity`);
    const isolatedOperation = watch(`${plantComponentType}.${plantComponent.plantComponentId}.isolatedOperation`);

    const filterAndUpdatePlantComponentContext = (updatedPlantComponent: PlantComponent[]) => {
        const filteredPlantComponents = [...updatedPlantComponent]
            .filter((pc) => pc)
            .filter((pc) => isValidQuantity(pc.quantity));

        updateContext({
            [plantComponentType]: filteredPlantComponents,
        });
        if (tableRef && tableRef.current) {
            tableRef.current.scrollTo(0, 0);
        }
    };

    React.useEffect(() => {
        if (isPlantComponentInContext) {
            setValue(
                `${plantComponentType}.${plantComponent.plantComponentId}.quantity`,
                contextPlantComponents[indexOfPlantComponent]?.quantity,
            );
            setValue(
                `${plantComponentType}.${plantComponent.plantComponentId}.isolatedOperation`,
                contextPlantComponents[indexOfPlantComponent]?.isolatedOperation,
            );
        }
    }, [
        contextPlantComponents,
        plantComponent,
        indexOfPlantComponent,
        isPlantComponentInContext,
        plantComponentType,
        setValue,
    ]);

    return (
        <div
            className={`grid grid-flow-col grid-cols-plant-component-table ${isSelected ? 'bg-light-grey' : 'bg-white'}`}
        >
            <span className="pl-3 py-3 border-b border-dark-grey flex items-center max-w-full min-w-0">
                <b className="truncate" title={plantComponent.plantComponentName}>
                    {plantComponent.plantComponentName}
                </b>
            </span>
            <span className="py-3 flex border-b border-dark-grey items-center px-3 truncate min-w-0">
                {plantComponent.manufacturerName}
            </span>
            <span className="py-3 flex border-b border-dark-grey items-center px-3">
                {formatNumber(plantComponent.apparentPower)} kVA
            </span>
            <span className="py-3 flex border-b border-dark-grey items-center px-3">
                {formatNumber(plantComponent.activePower)} kW
            </span>
            <span className="py-3 flex border-b border-dark-grey items-center px-3">
                <div className="flex items-center gap-x-2 w-full">
                    <div className="w-16">
                        <TextField
                            tooltipClassNames="w-36"
                            data-testid={`plant-component-quantity-${plantComponent.plantComponentId}`}
                            displayErrorMessage={
                                errors?.[plantComponentType]?.[plantComponent.plantComponentId]?.quantity &&
                                'Bitte geben Sie eine zwischen 0 und 9.999 an'
                            }
                            {...register(`${plantComponentType}.${plantComponent.plantComponentId}.quantity`, {
                                validate: (val) => (!val && !isolatedOperation) || isValidQuantity(val),
                                onBlur: () => {
                                    const copiedPlantComponents = [...contextPlantComponents];
                                    if (!isValidQuantity(quantity)) {
                                        if (isPlantComponentInContext) {
                                            copiedPlantComponents[indexOfPlantComponent] = undefined;
                                            filterAndUpdatePlantComponentContext(copiedPlantComponents);
                                        }
                                        return;
                                    }

                                    if (!isPlantComponentInContext) {
                                        contextPlantComponents.push({
                                            ...plantComponent,
                                            quantity: +quantity,
                                            isolatedOperation,
                                        });
                                    } else {
                                        copiedPlantComponents[indexOfPlantComponent].quantity = quantity;
                                    }
                                    filterAndUpdatePlantComponentContext(contextPlantComponents);
                                },
                            })}
                        />
                    </div>
                    Stück
                </div>
            </span>
            <span className="py-3 flex border-b border-dark-grey items-center h-full">
                <div className="flex items-center justify-center w-full h-full">
                    <Checkbox
                        data-testid={`plant-component-isolated-operation-${plantComponent.plantComponentId}`}
                        {...register(`${plantComponentType}.${plantComponent.plantComponentId}.isolatedOperation`, {
                            onChange: (e) => {
                                const { checked } = e.target;
                                const copiedPlantComponents = [...contextPlantComponents];

                                if (!isPlantComponentInContext) {
                                    contextPlantComponents.push({
                                        ...plantComponent,
                                        quantity,
                                        isolatedOperation: checked,
                                    });
                                } else {
                                    copiedPlantComponents[indexOfPlantComponent].isolatedOperation = checked;
                                }
                                filterAndUpdatePlantComponentContext(contextPlantComponents);
                            },
                        })}
                    />
                </div>
            </span>
            <span className="py-3 flex border-b border-dark-grey items-center justify-center">
                {quantity && (
                    <button
                        className="text-primary fill-current"
                        type="button"
                        data-testid={`plant-component-reset-${plantComponent.plantComponentId}`}
                        aria-label="Löschen"
                        onClick={() => {
                            const copiedPlantComponents = [...contextPlantComponents];

                            setValue(`${plantComponentType}.${plantComponent.plantComponentId}.quantity`, null);
                            setValue(
                                `${plantComponentType}.${plantComponent.plantComponentId}.isolatedOperation`,
                                false,
                            );

                            if (isPlantComponentInContext) {
                                copiedPlantComponents[indexOfPlantComponent] = undefined;
                                filterAndUpdatePlantComponentContext(copiedPlantComponents);
                            }
                        }}
                    >
                        <IconsCloseMenu className="w-6" />
                    </button>
                )}
            </span>
        </div>
    );
};
