import { format } from 'date-fns';

import {
    Address,
    CadastralDistrict,
    ConnectionAssurance,
    ConnectionAssuranceServiceType,
    Person,
    Telephone,
    isLocationAddress,
} from '../types';
import { BranchType } from '../types/BranchType';
import {
    ConnectionAssuranceAddressLocation,
    ConnectionAssuranceCadastralDistrictLocation,
} from '../types/ConnectionAssuranceLocation';
import { GeneralGeneratorType } from '../types/GeneratorData';
import { CompanyName, InternationalAddress, PlantOperator } from '../types/PlantData';
import { getCountryName } from './countries';
import { isCustomerCompany, isPrivatePlantOperator, isSaxony } from './guards';

export const formatContractAddress = (address: Address): [string, string] => [
    `${address.street} ${address.houseNumber}`,
    `${address.postalCode} ${address.city}`,
];

export const formatContractCadastralDistrict = (district: CadastralDistrict): string => {
    const { districtName, districtId, cadastralDistrict, cadastralUnitCounter, cadastralUnitDenominator, stateCode } =
        district;
    return (
        `${districtName} (${districtId})` +
        `${Number.isFinite(+cadastralDistrict) && stateCode !== 'SN' ? `, ${+cadastralDistrict}` : ''}` +
        `, ${+cadastralUnitCounter}` +
        `${+cadastralUnitDenominator ? `/${+cadastralUnitDenominator}` : ''}`
    );
};

export const getContractLocation = (location: Address | CadastralDistrict) =>
    isLocationAddress(location)
        ? `${formatContractAddress(location)[0]}, ${formatContractAddress(location)[1]}`
        : `${formatContractCadastralDistrict(location)}`;

export const getHint = (offerType: BranchType) => {
    const hintElectricity =
        'Die Hausanschlusskosten enthalten die Aufwendungen für ' +
        'Kabelverlegung, -anschluss, Mauerdurchbruch, Hausanschlusskasten und die ' +
        'Inbetriebnahme des Netzanschlusses.';
    const hintGas =
        'Die Hausanschlusskosten enthalten die Aufwendungen für Leitungsverlegung, -anschluss, ' +
        'Mauerdurchbruch, Hausanschlusskasten und die Inbetriebnahme des Netzanschlusses.';
    return offerType === 'electricity' ? hintElectricity : hintGas;
};

export const formatDate = (dateInput: string | Date, dirtyFormatString = 'dd.MM.yyyy'): string => {
    let date: Date;
    if (typeof dateInput === 'string') {
        date = new Date(dateInput);
    } else {
        date = dateInput;
    }

    return format(date, dirtyFormatString);
};

export const translateBranch = (branch: BranchType): string => {
    switch (branch) {
        case 'gas':
            return 'Erdgas';
        case 'electricity':
            return 'Strom';
        default:
            return '';
    }
};

const generatorTypeMap = {
    solarEnergy: 'Solaranlage',
    windEnergy: 'Windanlage',
    biogas: 'Biogasanlage',
    naturalGas: 'Erdgasanlage',
    geothermal: 'Geothermieanlage',
    oil: 'Ölanlage',
    water: 'Wasseranlage',
};

export const mapGeneralGeneratorTypeToString = (generatorType: GeneralGeneratorType): string => {
    if (generatorType === undefined) {
        return '';
    }
    if (generatorType.otherEnergyType && generatorType.generatorType === 'otherEnergy') {
        return generatorTypeMap[generatorType.otherEnergyType];
    }
    return generatorTypeMap[generatorType.generatorType];
};

export const formatConnectionAssuranceAddress = (address: ConnectionAssuranceAddressLocation): [string, string] => [
    `${address.streetName} ${address.housenumber}${address.housenumberAddition ? address.housenumberAddition : ''}`,
    `${address.postcode} ${address.cityName}`,
];

export const formatConnectionAssuranceCadastralDistrict = (
    district: ConnectionAssuranceCadastralDistrictLocation,
): string => {
    const { districtName, districtId, cadastralDistrict, cadastralUnitCounter, cadastralUnitDenominator, stateCode } =
        district;
    return (
        `${districtName} (${districtId})` +
        `${+cadastralDistrict && !isSaxony(stateCode) ? `, ${+cadastralDistrict}` : ''}` +
        `, ${+cadastralUnitCounter}` +
        `${+cadastralUnitDenominator ? `/${+cadastralUnitDenominator}` : ''}`
    );
};

const formatAcademicTitle = (person: Person) => {
    switch (person.academicTitle) {
        case 'Dr':
            return 'Dr. ';
        case 'Prof':
            return 'Prof. ';
        case 'ProfDr':
            return 'Prof. Dr. ';
        default:
            return undefined;
    }
};

export const getNameForCustomer = (customer: Person | CompanyName) =>
    isCustomerCompany(customer)
        ? `${customer.companyName}`
        : `${formatAcademicTitle(customer) || ''}${customer.firstName} ${customer.lastName}`;

export const formatPlantOperatorPersonName = (plantOperator: Person): string =>
    `${formatAcademicTitle(plantOperator) || ''}${plantOperator.firstName} ${plantOperator.lastName}`;

export const formatPlantOperatorCompanyName = (plantOperator: CompanyName): string => `${plantOperator.companyName}`;

export const formatPlantOperatorName = (plantOperator: PlantOperator): string => {
    if (isPrivatePlantOperator(plantOperator)) {
        return formatPlantOperatorPersonName(plantOperator.person);
    }
    return formatPlantOperatorCompanyName(plantOperator.company);
};

export const formatGermanAddress = (address: Address): string[] => [
    `${address.street} ${address.houseNumber}`,
    `${address.postalCode} ${address.city}`,
    `Deutschland`,
];

export const formatInternationalAddress = (address: InternationalAddress): string[] => [
    `${address.intStreet} ${address.intHouseNumber}`,
    `${address.intPostalCode} ${address.intCity}`,
    `${getCountryName(address.intCountry)}` || '',
];

export const formatTelephoneNumber = (telephone: Telephone): string =>
    `+${telephone.countryPrefix} ${telephone.number}`;

export const formatConnectionAssuranceServiceType = (service: ConnectionAssuranceServiceType) => {
    if (service === 'newConstruction') {
        return 'Neuerrichtung';
    }
    if (service === 'componentReplacement') {
        return 'Komponentenaustauch';
    }
    if (service === 'plantExpansion') {
        return 'Anlagenerweiterung';
    }
    return '';
};

export const formatConnectionAssuranceProjectHeading = (connectionAssurance: ConnectionAssurance): string =>
    [
        formatConnectionAssuranceServiceType(connectionAssurance.service),
        connectionAssurance.generatorData
            ? mapGeneralGeneratorTypeToString(connectionAssurance.generatorData?.generalGeneratorType)
            : 'Energiespeicher',
        '-',
        formatPlantOperatorName(connectionAssurance.plantData.plantOperator),
        `(${connectionAssurance.processCommunicationId})`,
    ].join(' ');
