export type PlantSize = 'smallest' | 'small' | 'medium' | 'big';

type PlantSizeProps = {
    plantSize?: PlantSize;
};

export const plantSizeIsAtLeastOfMediumSize = (context: PlantSizeProps) =>
    context?.plantSize === 'big' || context?.plantSize === 'medium';

export const plantSizeIsBig = (context: PlantSizeProps) => context.plantSize === 'big';

export const plantSizeIsAtMostOfSmallSize = (context: PlantSizeProps) =>
    context.plantSize === 'smallest' || context.plantSize === 'small';
