import appConfig from '@/app.config';
import { ApiParams } from '@/hooks/useApi';

const contractServiceUrl = appConfig.services.contractApi;

export async function createStorageContainer({
    customerId,
    axios,
    params,
}: ApiParams<{
    connectionAssuranceId: string;
}>): Promise<void> {
    await axios.post(
        `${contractServiceUrl}/account/${customerId}/connection-assurance/${params.connectionAssuranceId}/container`,
    );
}
