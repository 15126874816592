import { CommissioningStationDocumentsFileCategory } from '@/types/FileUpload';
import { InfoI } from '@ten-netzkundenportal/ui-components';
import React, { MutableRefObject } from 'react';

import PrintOutLink from '../PrintOutLink';

export default (
    fileCategory: CommissioningStationDocumentsFileCategory,
    boundaryDefiningParentRef: MutableRefObject<HTMLElement>,
) => {
    switch (fileCategory) {
        case 'commissioningRegistrationForm':
            return (
                <>
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Energiepartner/td32_anmeldung_inbetriebnahme_Station.pdf" />
                    <span className="checklist-info-i more-margin">
                        <InfoI
                            text={<span className="hyphens-auto">Sofern Sie eine Anschlussnehmerstation planen.</span>}
                            boundaryDefiningParentRef={boundaryDefiningParentRef}
                        />
                    </span>
                </>
            );
        case 'higherDecouplingProtectionTestProtocol':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={<span className="hyphens-auto">Sofern Sie eine Anschlussnehmerstation planen.</span>}
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );
        case 'documentsForTAB':
            return (
                <span className="checklist-info-i">
                    <InfoI
                        text={
                            <span className="hyphens-auto">
                                Unterlagen entsprechend VDE-AR-N 4120 Anwendungsregel, sofern Sie ein eigenes
                                Umspannwerk planen.
                            </span>
                        }
                        boundaryDefiningParentRef={boundaryDefiningParentRef}
                    />
                </span>
            );

        default:
            return undefined;
    }
};
