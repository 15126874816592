import { MetaObject } from '@/types/MetaObject';
import { mergeDeep } from '@/utils/object';
import { assign } from 'xstate';

import { ALL_STEPS } from './constants';
import { Context } from './context';
import { UpdateContextEvent, UpdatePathEvent, UpdateWizardStepsEvent, UserEvent } from './events';

/*
The Steps:
0: Plant Components
1: Documents
2: Completion Confirmation
 */

export default {
    UPDATE_CONTEXT: assign<Context, UserEvent>((context: Context, event: UpdateContextEvent) =>
        mergeDeep(context, event.value),
    ),

    UPDATE_FURTHEST_STEPS: assign<Context, UpdateWizardStepsEvent>((context: Context, event, { action }) => {
        const { process, subprocess } = action as unknown as UpdateWizardStepsEvent;

        const positionOfFurthestProcess = ALL_STEPS.findIndex(
            (step) => step === context.meta.furthestStep?.furthestProcess,
        );
        const positionOfCurrentProcess = ALL_STEPS.findIndex((step) => step === process);
        if (
            positionOfFurthestProcess < positionOfCurrentProcess ||
            (positionOfFurthestProcess === positionOfCurrentProcess &&
                context.meta.furthestStep?.furthestSubprocess < subprocess)
        ) {
            return {
                ...(context as Partial<Context>),
                ...({
                    meta: {
                        ...context.meta,
                        ...{
                            furthestStep: {
                                furthestProcess: process,
                                furthestSubprocess: subprocess,
                            },
                        },
                    },
                } as Partial<MetaObject>),
            };
        }
        return context;
    }),

    UPDATE_PATH: assign<Context, UpdatePathEvent>((context: Context, _, { action }) => {
        const { path } = action as unknown as UpdatePathEvent;
        return {
            ...(context as Partial<Context>),
            ...({
                meta: {
                    ...context.meta,
                    path,
                },
            } as Partial<MetaObject>),
        };
    }),

    SYNC_LOCATION_WITH_STATE: (context: Context): void => {
        const pathArray = context.meta.history.location.pathname.split('/');
        // Check if the last part of the path is inbetriebsetzung, when it is, replace it so navigating from the first page with
        // the browser back button doesn't make it go to /inbetriebsetuzung, instead directly into the dashboard
        if (pathArray.length > 0 && pathArray[pathArray.length - 1] === 'inbetriebsetzung') {
            context.meta.history.replace(context.meta.path);
        } else {
            context.meta.history.push(context.meta.path);
        }
    },
};
