import { NumberOfLocation } from '@/types/ConnectionAssuranceLocation';
import { PlantComponent } from '@/types/PlantComponent';
import { PlantSize } from '@/types/PlantSize';
import { getCommissioningGenerationPlantDocumentsFileCategories } from '@components/documentUpload/documentUploadForm/commissioning-generation-plant-documents/getFileCategory';
import { getCommissioningStationDocumentsFileCategories } from '@components/documentUpload/documentUploadForm/commissioning-station-documents/getFileCategory';
import { getCompensationDocumentsFileCategories } from '@components/documentUpload/documentUploadForm/compensation-documents/getFileCategory';
import { getConnectionRelevantDocumentsFileCategories } from '@components/documentUpload/documentUploadForm/connection-relevant-document/getFileCategory';
import { getNetworkCompatibilityAssessmentDocumentsFileCategories } from '@components/documentUpload/documentUploadForm/network-compatibility-assessment-documents/getFileCategory';
import { getNetworkSecurityManagementDocumentsFileCategories } from '@components/documentUpload/documentUploadForm/network-security-management-documents/getFileCategory';
import { getOtherDocumentsFileCategories } from '@components/documentUpload/documentUploadForm/other-documents/getFileCategory';
import { UploadGroup } from '@ten-netzkundenportal/ui-document-upload';

type GetUploadGroupsProps = {
    plantSize: PlantSize;
    plantComponents: PlantComponent[];
    numberOfLocation: NumberOfLocation;
    showCompensationDocuments: boolean;
    showRequiredDocumentsSection: boolean;
};

export function getUploadGroups({
    plantSize,
    plantComponents,
    numberOfLocation,
    showCompensationDocuments,
    showRequiredDocumentsSection,
}: GetUploadGroupsProps): UploadGroup[] {
    const groups = [
        numberOfLocation === 'many'
            ? {
                  id: 'networkCompatibilityAssessmentDocuments',
                  heading: 'Unterlagen zur Netzverträglichkeitsprüfung',
                  fileCategories:
                      getNetworkCompatibilityAssessmentDocumentsFileCategories(showRequiredDocumentsSection),
              }
            : undefined,
        {
            id: 'connectionRelevantDocuments',
            heading: 'Anschlussrelevante Projektunterlagen',
            fileCategories: getConnectionRelevantDocumentsFileCategories(
                plantSize,
                plantComponents,
                showRequiredDocumentsSection,
            ),
        },
        plantSize === 'big'
            ? {
                  id: 'commissioningStationDocuments',
                  heading:
                      'Notwendige Unterlagen vor Inbetriebnahme der Übergabe- / ' +
                      'Transformatorenstation (Anschlussnehmerstation) oder des Umspannwerkes',
                  fileCategories: getCommissioningStationDocumentsFileCategories(),
              }
            : undefined,
        {
            id: 'commissioningGenerationPlantDocuments',
            heading: 'Notwendige Unterlagen zur Inbetriebsetzung der Erzeugungsanlage',
            fileCategories: getCommissioningGenerationPlantDocumentsFileCategories(plantSize, plantComponents),
        },
        {
            id: 'networkSecurityManagementDocuments',
            heading: 'Netzsicherheitsmanagement',
            fileCategories: getNetworkSecurityManagementDocumentsFileCategories(plantSize, plantComponents),
        },
        showCompensationDocuments
            ? {
                  id: 'compensationDocuments',
                  heading: 'Vergütungsrelevante Unterlagen',
                  fileCategories: getCompensationDocumentsFileCategories(),
              }
            : undefined,
        {
            id: 'otherDocuments',
            heading: 'Sonstige Dokumente',
            fileCategories: getOtherDocumentsFileCategories(plantSize),
        },
    ].filter((option) => !!option && option.fileCategories.length);

    if (showRequiredDocumentsSection) {
        const requiredFileCategories = groups.flatMap((g) => g.fileCategories.filter((f) => f.required));

        if (requiredFileCategories.length === 0) {
            return groups;
        }

        return [
            {
                id: 'requiredDocuments',
                heading: 'Verpflichtende Unterlagen',
                fileCategories: requiredFileCategories,
            },
            ...groups
                .map((group) => ({
                    id: group.id,
                    heading: group.heading,
                    fileCategories: group.fileCategories.filter((f) => !f.required),
                }))
                .filter((group) => group.fileCategories.length),
        ];
    }

    return groups;
}
