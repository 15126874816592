export const countries = [
    {
        label: 'Belgien',
        value: 'belgium',
    },
    {
        label: 'Dänemark',
        value: 'denmark',
    },
    {
        label: 'Frankreich',
        value: 'france',
    },
    {
        label: 'Italien',
        value: 'italy',
    },
    {
        label: 'Liechtenstein',
        value: 'liechtenstein',
    },
    {
        label: 'Luxemburg',
        value: 'luxembourg',
    },
    {
        label: 'Niederlande',
        value: 'netherlands',
    },
    {
        label: 'Österreich',
        value: 'austria',
    },
    {
        label: 'Polen',
        value: 'poland',
    },
    {
        label: 'Schweden',
        value: 'sweden',
    },
    {
        label: 'Schweiz',
        value: 'switzerland',
    },
    {
        label: 'Tschechien',
        value: 'czechRepublic',
    },
];

export const getCountryName = (countryCode: string) => countries.find(({ value }) => value === countryCode).label;
