export const DASHBOARD = '/uebersicht';

export const ROOT_CONTRACT = '/vertrag';

export const CONTRACT_VIEW = '/:customerId/:processCommunicationId/vertragsansicht';
export const CONTRACT_INSTALLER_SELECTION = '/:customerId/:processCommunicationId/installateur';
export const CONTRACT_DOCUMENTS = '/:processCommunicationId/dokumente';
export const CONTRACT_DOCUMENTS_UPLOAD = '/:processCommunicationId/dokumente-hochladen';
export const CANCEL_CONTRACT = '/:customerId/:processCommunicationId/kuendigung';
export const CANCEL_CONTRACT_CONFIRMATION = '/:customerId/:processCommunicationId/kuendigungsbestaetigung';

export const INSTALLER = `/installateur`;
export const CONTRACT_COMMISSIONING_DATA = `/:processCommunicationId/inbetriebsetzungsdaten`;
export const CONTRACT_FINISH_COMMISSIONING = `/:processCommunicationId/fertigstellungsanzeige`;
export const CONTRACT_DETAILS_INSTALLER = `${INSTALLER}/:processCommunicationId/vertragsdetails`;

export const CONNECTION_ASSURANCE = '/anschlusszusage/:connectionAssuranceId';
export const CONNECTION_ASSURANCE_DETAILS = `${CONNECTION_ASSURANCE}/details`;
export const CONNECTION_ASSURANCE_DOCUMENTS = `${CONNECTION_ASSURANCE}/dokumente`;
export const CONNECTION_ASSURANCE_DOCUMENTS_UPLOAD = `${CONNECTION_ASSURANCE}/dokumenten-upload`;
export const CONNECTION_ASSURANCE_INSTALLER_SELECTION = `${CONNECTION_ASSURANCE}/installateur-auswahl`;

export const CONNECTION_ASSURANCE_COMMISSIONING = `${CONNECTION_ASSURANCE}/inbetriebsetzung`;
export const CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS = `${CONNECTION_ASSURANCE_COMMISSIONING}/anlagenkomponenten`;
export const CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_GENERATOR = `${CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS}/erzeugungsanlage`;
export const CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS_STORAGE = `${CONNECTION_ASSURANCE_COMMISSIONING_PLANT_COMPONENTS}/energiespeicher`;
export const CONNECTION_ASSURANCE_COMMISSIONING_DOCUMENT_UPLOAD = `${CONNECTION_ASSURANCE_COMMISSIONING}/dokumente`;
export const CONNECTION_ASSURANCE_COMMISSIONING_COMPLETION_CONFIRMATION = `${CONNECTION_ASSURANCE_COMMISSIONING}/abschlussbestaetigung`;
export const CONNECTION_ASSURANCE_COMMISSIONING_NEXT_STEPS = `${CONNECTION_ASSURANCE_COMMISSIONING}/naechste-schritte`;
