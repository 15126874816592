import { RedirectToDashboard } from '@/RedirectToDashboard';
import { ConnectionAssuranceCommissioningRoot } from '@/connection-assurance/commissioning/ConnectionAssuranceCommissioningRoot';
import ConnectionAssuranceDocumentsUpload from '@/connection-assurance/connection-assurance-documents-upload-view/ConnectionAssuranceDocumentsUpload';
import { AuthorizedContent } from '@ten-netzkundenportal/ui-auth-utils';
import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import {
    CONNECTION_ASSURANCE_COMMISSIONING,
    CONNECTION_ASSURANCE_DETAILS,
    CONNECTION_ASSURANCE_DOCUMENTS,
    CONNECTION_ASSURANCE_DOCUMENTS_UPLOAD,
    CONNECTION_ASSURANCE_INSTALLER_SELECTION,
} from '../routes';
import ConnectionAssuranceDetailsView from './connection-assurance-details-view/ConnectionAssuranceDetailsView';
import ConnectionAssuranceDocumentsView from './connection-assurance-documents-view/ConnectionAssuranceDocumentsView';
import ConnectionAssuranceInstallerSelection from './connection-assurance-installer-selection/ConnectionAssuranceInstallerSelection';

const ConnectionAssuranceRoot = () => {
    const { connectionAssuranceId } = useParams<{ connectionAssuranceId: string }>();

    return (
        <Switch>
            <Route path={CONNECTION_ASSURANCE_DOCUMENTS_UPLOAD}>
                <ConnectionAssuranceDocumentsUpload connectionAssuranceId={connectionAssuranceId} />
            </Route>
            <Route path={CONNECTION_ASSURANCE_DETAILS}>
                <ConnectionAssuranceDetailsView connectionAssuranceId={connectionAssuranceId} />
            </Route>
            <Route path={CONNECTION_ASSURANCE_DOCUMENTS}>
                <ConnectionAssuranceDocumentsView connectionAssuranceId={connectionAssuranceId} />
            </Route>
            <Route path={CONNECTION_ASSURANCE_INSTALLER_SELECTION}>
                <AuthorizedContent acceptedCustomerTypes={['private', 'company', 'projectpartner']}>
                    <ConnectionAssuranceInstallerSelection connectionAssuranceId={connectionAssuranceId} />
                </AuthorizedContent>
            </Route>
            <Route path={CONNECTION_ASSURANCE_COMMISSIONING}>
                <AuthorizedContent acceptedCustomerTypes={['installer']}>
                    <ConnectionAssuranceCommissioningRoot />
                </AuthorizedContent>
            </Route>
            <Route component={RedirectToDashboard} />
        </Switch>
    );
};

export default ConnectionAssuranceRoot;
