import { OtherDocumentsFileCategory } from '@/types/FileUpload';
import { PlantSize } from '@/types/PlantSize';
import { UploadFileCategory } from '@ten-netzkundenportal/ui-document-upload';
import { MutableRefObject } from 'react';

import getContentPerFileCategory from './getContentPerFileCategory';

type Option = {
    label: string;
    value: OtherDocumentsFileCategory;
};

export function getOtherDocumentsFileCategories(plantSize: PlantSize): UploadFileCategory[] {
    const options: Option[] = [
        plantSize === 'small'
            ? { label: 'Fotodokumentation Hausanschluss', value: 'houseConnectionPhotoDocumentation' }
            : undefined,
        { label: 'Datenerfassungsblatt Ladeinfrastruktur', value: 'chargingInfrastructureDataCollectionSheet' },
        { label: 'Sonstige', value: 'other' },
    ];

    return options
        .filter((option) => !!option)
        .map((option) => ({
            ...option,
            createDescription: (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategory(option.value, boundaryDefiningParentRef),
            tags: { category: 'otherDocuments', subCategory: option.value },
        }));
}
