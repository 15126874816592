import { GeoCoordinate } from '@/types/GeoCoordinate';
import electricityConnectionIcon from '@ten-netzkundenportal/ui-components/dist/assets/electricityConnectionIcon.svg';
import energyStorageSystemIcon from '@ten-netzkundenportal/ui-components/dist/assets/energyStorageSystemIcon.svg';
import generatorConnectionIcon from '@ten-netzkundenportal/ui-components/dist/assets/generatorConnectionIcon.svg';
import L from 'leaflet';

const iconStyle: Omit<L.IconOptions, 'iconUrl'> = {
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    shadowSize: [0, 0],
};
const getMarkerOptions = (
    iconUrl: string,
    iconOptions: Omit<L.IconOptions, 'iconUrl'> = {},
    markerOptions: L.MarkerOptions = {},
): L.MarkerOptions => ({
    icon: L.icon({
        iconUrl,
        ...iconStyle,
        ...iconOptions,
    }),
    opacity: 1,
    ...markerOptions,
});
export const drawGeneratorMarker = (coordinate: GeoCoordinate, map: L.Map) => {
    const generatorMarker = L.marker(coordinate, getMarkerOptions(generatorConnectionIcon));
    map.addLayer(generatorMarker);
    return generatorMarker;
};

export const drawEnergyStorageMarker = (coordinate: GeoCoordinate, map: L.Map) => {
    const energyStorageMarker = L.marker(coordinate, getMarkerOptions(energyStorageSystemIcon));
    map.addLayer(energyStorageMarker);
    return energyStorageMarker;
};

export const drawSelectedConnectionPointMarker = (coordinate: GeoCoordinate, map: L.Map) => {
    const electricityMarker = new L.Marker(
        coordinate,
        getMarkerOptions(electricityConnectionIcon, undefined, {
            zIndexOffset: 30,
            draggable: false,
        }),
    );
    map.addLayer(electricityMarker);
};
