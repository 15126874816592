import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';

import DocumentsView from '../../components/documentsView/connection-assurance/DocumentsView';
import useApi from '../../hooks/useApi';
import { CONNECTION_ASSURANCE_DOCUMENTS_UPLOAD, DASHBOARD } from '../../routes';
import { PersistedDocumentResponse } from '../../types/Document';
import getAllDocuments from '../api/getAllDocuments';
import getDocument from '../api/getDocument';

interface ConnectionAssuranceDocumentsViewProps {
    connectionAssuranceId: string;
}

const ConnectionAssuranceDocumentsView = ({
    connectionAssuranceId,
}: ConnectionAssuranceDocumentsViewProps): React.ReactElement => {
    const getAllDocumentsApi = useApi(getAllDocuments);
    const getDocumentApi = useApi(getDocument);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [documentResponse, setDocumentResponse] = useState<PersistedDocumentResponse>(undefined);

    useEffect(() => {
        setIsLoading(true);
        getAllDocumentsApi({ connectionAssuranceId })
            .then((value) => setDocumentResponse(value))
            .catch((e) => {
                console.error('Failed to load documents', e);
                setDocumentResponse(undefined);
            })
            .finally(() => setIsLoading(false));
    }, [connectionAssuranceId, getAllDocumentsApi]);

    return (
        <DocumentsView
            isLoading={isLoading}
            documentResponse={documentResponse}
            loadDocument={(documentId) => getDocumentApi({ connectionAssuranceId, documentId })}
            goBack={() => {
                window.history.pushState({}, document.title, DASHBOARD);
            }}
            uploadDocumentsPath={generatePath(CONNECTION_ASSURANCE_DOCUMENTS_UPLOAD, { connectionAssuranceId })}
        />
    );
};

export default ConnectionAssuranceDocumentsView;
