import appConfig from '@/app.config';
import { ApiParams } from '@/hooks/useApi';
import { SasResponse } from '@ten-netzkundenportal/ui-document-upload';

const contractServiceUrl = appConfig.services.contractApi;

export async function getSas({
    customerId,
    axios,
    params,
}: ApiParams<{
    connectionAssuranceId: string;
}>): Promise<SasResponse> {
    const response = await axios.get<{ sas: string; storageAccount: string }>(
        `${contractServiceUrl}/account/${customerId}/connection-assurance/${params.connectionAssuranceId}/sas`,
    );

    return response.data;
}
