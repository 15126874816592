import getContentPerFileCategoryForCommissioningGenerationPlantDocuments from '@/components/documentUpload/documentUploadForm/commissioning-generation-plant-documents/getContentPerFileCategory';
import getContentPerFileCategoryForConnectionRelevantDocuments from '@/components/documentUpload/documentUploadForm/connection-relevant-document/getContentPerFileCategory';
import getContentPerFileCategoryForNetworkSecurityManagementDocuments from '@/components/documentUpload/documentUploadForm/network-security-management-documents/getContentPerFileCategory';
import {
    CommissioningGenerationPlantDocumentsFileCategory,
    ConnectionRelevantDocumentFileCategory,
    NetworkSecurityManagementDocumentsFileCategory,
} from '@/types/FileUpload';
import { GeneratorData, GeneratorType } from '@/types/GeneratorData';
import { PlantComponent } from '@/types/PlantComponent';
import { PlantSize } from '@/types/PlantSize';
import { EnergyStorageSystemType } from '@/types/StorageData';
import { calculateSumOfActivePowersInKW } from '@/utils/plantComponents';
import PrintOutLink from '@components/documentUpload/documentUploadForm/PrintOutLink';
import { UploadFileCategory } from '@ten-netzkundenportal/ui-document-upload';
import React, { MutableRefObject } from 'react';

type NetworkCompatibilityAssessmentOption = {
    label: string;
    category: 'connectionRelevantDocuments';
    subCategory: ConnectionRelevantDocumentFileCategory;
};

type CommissioningGenerationPlantOption = {
    label: string;
    category: 'commissioningGenerationPlantDocuments';
    subCategory: CommissioningGenerationPlantDocumentsFileCategory;
};

type NetworkSecurityManagementOption = {
    label: string;
    category: 'networkSecurityManagementDocuments';
    subCategory: NetworkSecurityManagementDocumentsFileCategory;
};

const getCreateDescriptionFunction = (
    category:
        | 'connectionRelevantDocuments'
        | 'commissioningGenerationPlantDocuments'
        | 'networkSecurityManagementDocuments',
    subCategory:
        | ConnectionRelevantDocumentFileCategory
        | CommissioningGenerationPlantDocumentsFileCategory
        | NetworkSecurityManagementDocumentsFileCategory,
    plantSize: PlantSize,
) => {
    switch (category) {
        case 'connectionRelevantDocuments':
            return (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategoryForConnectionRelevantDocuments(
                    subCategory as ConnectionRelevantDocumentFileCategory,
                    boundaryDefiningParentRef,
                );
        case 'commissioningGenerationPlantDocuments':
            if (subCategory === 'commissioningDisplay') {
                return () => (
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Anschluss/ANS_Anmeldung_Formblatt_Strom.pdf" />
                );
            }

            return (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategoryForCommissioningGenerationPlantDocuments(
                    subCategory as CommissioningGenerationPlantDocumentsFileCategory,
                    boundaryDefiningParentRef,
                    plantSize,
                );
        case 'networkSecurityManagementDocuments':
            if (subCategory === 'radioRippleControlReceiverCommissioningOrShutdown') {
                return () => (
                    <PrintOutLink link="https://www.thueringer-energienetze.com/Content/Documents/Einspeisung/td31_inbetriebnahme_frste.pdf" />
                );
            }

            return (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategoryForNetworkSecurityManagementDocuments(
                    subCategory as NetworkSecurityManagementDocumentsFileCategory,
                    boundaryDefiningParentRef,
                );

        default:
            return undefined;
    }
};

export function getSmallPlantSizeRequiredDocuments(
    generatorType: GeneratorType,
    energyStorageSystem: EnergyStorageSystemType,
    plantComponentsGenerator: PlantComponent[],
    generatorData: GeneratorData,
) {
    const activePowerAbove25kW = calculateSumOfActivePowersInKW(plantComponentsGenerator) >= 25;
    const solarPowerAbove25kWp = generatorType === 'solarEnergy' && generatorData.solarModuleCapacityInkWp >= 25;
    const commissioningGenerationPlantOptions: CommissioningGenerationPlantOption[] = [
        generatorType === 'solarEnergy'
            ? {
                  label: 'Fotodokumentation Solarmodulfeld',
                  category: 'commissioningGenerationPlantDocuments',
                  subCategory: 'solarModulePhotoDocumentation',
              }
            : undefined,
        generatorType === 'solarEnergy' || energyStorageSystem === 'dcSystem'
            ? {
                  label: 'Fotodokumentation Wechselrichtertypenschild',
                  category: 'commissioningGenerationPlantDocuments',
                  subCategory: 'inverterTypePlatePhotoDocumentation',
              }
            : undefined,
        energyStorageSystem === 'acSystem'
            ? {
                  label: 'Fotodokumentation Energiespeichersystem',
                  category: 'commissioningGenerationPlantDocuments',
                  subCategory: 'energyStorageSystemPhotoDocumentation',
              }
            : undefined,
        generatorType && generatorType !== 'solarEnergy'
            ? {
                  label: 'Fotodokumentation Generatortypenschild',
                  category: 'commissioningGenerationPlantDocuments',
                  subCategory: 'generatorTypePlatePhotoDocumentation',
              }
            : undefined,
    ];

    const networkSecurityManagementOptions: NetworkSecurityManagementOption[] = [
        solarPowerAbove25kWp || activePowerAbove25kW
            ? {
                  label: 'Inbetriebnahme/Außerbetriebnahme Funkrundsteuerempfänger',
                  category: 'networkSecurityManagementDocuments',
                  subCategory: 'radioRippleControlReceiverCommissioningOrShutdown',
              }
            : undefined,
    ];

    return [...commissioningGenerationPlantOptions, ...networkSecurityManagementOptions];
}

export function getCommissioningRequiredDocumentsFileCategories(
    plantSize: PlantSize,
    generatorType: GeneratorType,
    energyStorageSystem: EnergyStorageSystemType,
    existingFileCategories: Set<string>,
    generatorData: GeneratorData,
    plantComponentsGenerator: PlantComponent[],
) {
    const options: (
        | NetworkCompatibilityAssessmentOption
        | CommissioningGenerationPlantOption
        | NetworkSecurityManagementOption
    )[] = [
        {
            label: 'Anmeldung Netzanschluss inklusive Inbetriebsetzung',
            category: 'commissioningGenerationPlantDocuments',
            subCategory: 'commissioningDisplay',
        },
        {
            label: 'Übersichtsschaltplan',
            category: 'connectionRelevantDocuments',
            subCategory: 'wiringOverviewDiagram',
        },
        ...(plantSize === 'small'
            ? getSmallPlantSizeRequiredDocuments(
                  generatorType,
                  energyStorageSystem,
                  plantComponentsGenerator,
                  generatorData,
              )
            : []),
    ];

    return options
        .filter((option) => !!option)
        .map(
            (option) =>
                ({
                    label: option.label,
                    value: option.subCategory,
                    createDescription: getCreateDescriptionFunction(option.category, option.subCategory, plantSize),
                    required: !existingFileCategories.has(option.subCategory),
                    tags: {
                        category: option.category,
                        subCategory: option.subCategory,
                    },
                }) satisfies UploadFileCategory,
        );
}
