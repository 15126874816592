import appConfig from '../../app.config';
import { ApiParams } from '../../hooks/useApi';

const getDocument = async ({
    axios,
    params,
    customerId,
}: ApiParams<{ connectionAssuranceId: string; documentId: string }>): Promise<{
    blob: Blob;
    type: string;
}> => {
    const response = await axios.get(
        `${appConfig.services.contractApi}/account/${customerId}/connection-assurance/${params.connectionAssuranceId}/document/${params.documentId}`,
        {
            responseType: 'blob',
        },
    );

    if (response.status === 200) {
        const contentType = response.headers['content-type'];

        if (typeof contentType === 'string') {
            return {
                blob: response.data,
                type: contentType,
            };
        }
    }
    throw new Error('Can not fetch document');
};

export default getDocument;
