import { NetworkSecurityManagementDocumentsFileCategory } from '@/types/FileUpload';
import { PlantComponent } from '@/types/PlantComponent';
import { PlantSize } from '@/types/PlantSize';
import { calculateSumOfActivePowersInKW } from '@/utils/plantComponents';
import { UploadFileCategory } from '@ten-netzkundenportal/ui-document-upload';
import { MutableRefObject } from 'react';

import getContentPerFileCategory from './getContentPerFileCategory';

type Option = {
    label: string;
    value: NetworkSecurityManagementDocumentsFileCategory;
};

export function getNetworkSecurityManagementDocumentsFileCategories(
    plantSize: PlantSize,
    plantComponents: PlantComponent[],
): UploadFileCategory[] {
    const totalActivePower = calculateSumOfActivePowersInKW(plantComponents);

    const options: Option[] = [
        plantSize === 'big'
            ? {
                  label: 'Protokoll/Errichterbestätigung FWA',
                  value: 'protocolFWAInstallerConfirmation',
              }
            : undefined,
        totalActivePower >= 100
            ? {
                  label: 'Auftrag VPN Router',
                  value: 'vpnRouterOrder',
              }
            : undefined,
        totalActivePower >= 100
            ? {
                  label: 'Betriebsbereitschaftserklärung Fernwirkankopplung',
                  value: 'telecontrolCouplingOperationReadinessDeclaration',
              }
            : undefined,
        {
            label: 'Auftrag Funkrundsteuerempfänger',
            value: 'radioRippleControlReceiverOrder',
        },
        {
            label: 'Inbetriebnahme/Außerbetriebnahme  Funkrundsteuerempfänger',
            value: 'radioRippleControlReceiverCommissioningOrShutdown',
        },
    ];

    return options
        .filter((option) => !!option)
        .map((option) => ({
            ...option,
            createDescription: (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategory(option.value, boundaryDefiningParentRef),
            tags: { category: 'networkSecurityManagementDocuments', subCategory: option.value },
        }));
}
