import appConfig from '../../app.config';
import { ApiParams } from '../../hooks/useApi';
import { PersistedDocumentResponse } from '../../types/Document';

const getAllDocuments = async ({
    axios,
    params,
    customerId,
}: ApiParams<{ connectionAssuranceId: string }>): Promise<PersistedDocumentResponse> => {
    const response = await axios.get(
        `${appConfig.services.contractApi}/account/${customerId}/connection-assurance/${params.connectionAssuranceId}/document`,
    );

    if (response.status === 200) {
        return response.data;
    }

    throw new Error('Can not fetch documents');
};

export default getAllDocuments;
