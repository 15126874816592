export type PlantSize = 'smallest' | 'small' | 'medium' | 'big';

export const plantSizeWithLabel = [
    {
        value: 'smallest',
        label: 'Balkonkraftwerk bis 0,8 kW',
    },
    {
        value: 'small',
        label: 'Anlage bis 30 kW',
    },
    {
        value: 'medium',
        label: 'Anlage über 30 kW',
    },
    {
        value: 'big',
        label: 'Anlage über 500 kW',
    },
];
