import { CommissioningData } from '@/connection-assurance/commissioning/types/CommissioningData';
import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import { validateDateInput } from '@/utils/validateDateInput';
import { TextField } from '@ten-netzkundenportal/ui-components';
import { addYears } from 'date-fns';
import * as React from 'react';
import { FieldErrors } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldPath } from 'react-hook-form/dist/types/path';

type DateFormItemProps = {
    title?: string;
    label: React.ReactNode;
    id: FieldPath<CommissioningData>;
    register: UseFormRegister<CommissioningData>;
    errors: FieldErrors<CommissioningData>;
};

export const CommissioningDateFormItem = ({ title, label, id, register, errors }: DateFormItemProps) => (
    <>
        {title && <p className="col-span-4 font-bold mt-6">{title}</p>}

        <div className="col-span-3 pt-4">{label}</div>
        <div className="flex justify-center">
            <TextField
                name={id}
                label="Datum"
                placeholder="tt.mm.jjjj"
                {...register(id, {
                    validate: (input: string) =>
                        validateDateInput(input, {
                            minDate: addYears(new Date(), -2),
                            maxDate: new Date(),
                        }),
                    shouldUnregister: true,
                    required: REQUIRED_ERROR_MESSAGE,
                })}
                size={10}
                displayErrorMessage={errors[id]?.message}
            />
        </div>
    </>
);
