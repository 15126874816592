import { ConnectionAssurance } from '@/types/ConnectionAssurance';
import {
    drawEnergyStorageMarker,
    drawGeneratorMarker,
    drawSelectedConnectionPointMarker,
} from '@/utils/drawConnectionAssuranceMarker';
import drawSolarModuleArea from '@/utils/drawSolarModuleArea';
import L from 'leaflet';
import { useEffect } from 'react';

const useDrawConnectionDataProperties = (process: ConnectionAssurance, map: L.Map) => {
    const { generatorMarkers, energyStorageMarker, solarModuleAreas, electricityMarker } =
        process.connectionDataProperties;

    const isDrawing = Boolean(
        electricityMarker || generatorMarkers?.length > 0 || energyStorageMarker || solarModuleAreas?.length > 0,
    );

    useEffect(() => {
        if (map) {
            if (electricityMarker) {
                drawSelectedConnectionPointMarker(electricityMarker, map);
            }

            if (generatorMarkers?.length > 0) {
                generatorMarkers.forEach((generatorMarkerCoordinate) => {
                    drawGeneratorMarker(generatorMarkerCoordinate, map);
                });
            }

            if (energyStorageMarker) {
                drawEnergyStorageMarker(energyStorageMarker, map);
            }

            if (solarModuleAreas) {
                const solarModuleAreaList = solarModuleAreas?.map((shape, index) => ({ key: index, value: shape }));
                drawSolarModuleArea(solarModuleAreaList, map);
            }
        }
    }, [electricityMarker, energyStorageMarker, generatorMarkers, map, solarModuleAreas]);

    return {
        isDrawing,
    };
};

export default useDrawConnectionDataProperties;
