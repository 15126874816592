// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Textarea Class */

#single-spa-application\\:\\@ten-nkp\\/contract-app .textarea-additional-message{resize: none}
#single-spa-application\\:\\@ten-nkp\\/contract-app .textarea-additional-message::-webkit-input-placeholder {padding-top: 2rem;}
#single-spa-application\\:\\@ten-nkp\\/contract-app .textarea-additional-message:-moz-placeholder { /* Firefox 18- */padding-top: 2rem;}
#single-spa-application\\:\\@ten-nkp\\/contract-app .textarea-additional-message::-moz-placeholder {  /* Firefox 19+ */padding-top: 2rem;}
#single-spa-application\\:\\@ten-nkp\\/contract-app .textarea-additional-message:-ms-input-placeholder {padding-top: 2rem;}
#single-spa-application\\:\\@ten-nkp\\/contract-app .textarea-additional-message:focus::-webkit-input-placeholder {color: transparent;}
#single-spa-application\\:\\@ten-nkp\\/contract-app .textarea-additional-message:focus::-moz-placeholder {color: transparent;}

#single-spa-application\\:\\@ten-nkp\\/contract-app .textarea-custom {
    border-color: #d9d9d9;
}
#single-spa-application\\:\\@ten-nkp\\/contract-app .textarea-custom::placeholder {
    color: #666666;
}
#single-spa-application\\:\\@ten-nkp\\/contract-app select:disabled {
    opacity: 1 !important;
}
#single-spa-application\\:\\@ten-nkp\\/contract-app .min-h-40 {
    min-height: 11rem;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB,8EAA6B,YAAY;AACzC,0GAAyD,iBAAiB,CAAC;AAC3E,iGAAgD,gBAAgB,CAAC,iBAAiB,CAAC;AACnF,mGAAkD,gBAAgB,CAAC,iBAAiB,CAAC;AACrF,qGAAoD,iBAAiB,CAAC;AACtE,gHAA+D,kBAAkB,CAAC;AAClF,uGAAsD,kBAAkB,CAAC;;AAEzE;IACI,qBAAqB;AACzB;AACA;IACI,cAAc;AAClB;AACA;IACI,qBAAqB;AACzB;AACA;IAKI,iBAAiB;AAHrB","sourcesContent":["/* Textarea Class */\n\n.textarea-additional-message{resize: none}\n.textarea-additional-message::-webkit-input-placeholder {padding-top: 2rem;}\n.textarea-additional-message:-moz-placeholder { /* Firefox 18- */padding-top: 2rem;}\n.textarea-additional-message::-moz-placeholder {  /* Firefox 19+ */padding-top: 2rem;}\n.textarea-additional-message:-ms-input-placeholder {padding-top: 2rem;}\n.textarea-additional-message:focus::-webkit-input-placeholder {color: transparent;}\n.textarea-additional-message:focus::-moz-placeholder {color: transparent;}\n\n.textarea-custom {\n    border-color: #d9d9d9;\n}\n.textarea-custom::placeholder {\n    color: #666666;\n}\nselect:disabled {\n    opacity: 1 !important;\n}\n.min-h-40 {\n    min-height: 10rem;\n}\n\n.min-h-40 {\n    min-height: 11rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
