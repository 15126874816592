import { NetworkCompatibilityAssessmentDocumentsFileCategory } from '@/types/FileUpload';
import { UploadFileCategory } from '@ten-netzkundenportal/ui-document-upload';
import { MutableRefObject } from 'react';

import getContentPerFileCategory from './getContentPerFileCategory';

type Option = {
    label: string;
    value: NetworkCompatibilityAssessmentDocumentsFileCategory;
    required: boolean;
};

export function getNetworkCompatibilityAssessmentDocumentsFileCategories(
    showRequiredDocumentsSection: boolean,
): UploadFileCategory[] {
    const options: Option[] = [
        {
            label: 'Lageplan',
            value: 'sitePlan',
            required: showRequiredDocumentsSection,
        },
    ];

    return options
        .filter((option) => !!option)
        .map((option) => ({
            ...option,
            createDescription: (boundaryDefiningParentRef: MutableRefObject<HTMLElement>) =>
                getContentPerFileCategory(option.value, boundaryDefiningParentRef),
            tags: { category: 'networkCompatibilityAssessmentDocuments', subCategory: option.value },
        }));
}
