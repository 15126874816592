import { PlantComponent } from '@/types/PlantComponent';

export const isPlantComponentsGeneratorShown = (context: { plantComponentsGenerator?: PlantComponent[] }): boolean =>
    context.plantComponentsGenerator?.length > 0;

export const isPlantComponentsStorageShown = (context: { plantComponentsStorage?: PlantComponent[] }): boolean =>
    context.plantComponentsStorage?.length > 0;

export default {
    isPlantComponentsGeneratorShown,
    isPlantComponentsStorageShown,
};
