import { StyledLink } from '@ten-netzkundenportal/ui-components';
import React from 'react';

type Props = { link: string };
const PrintOutLink = ({ link }: Props) => (
    <>
        <br />
        (hier finden Sie den{' '}
        <StyledLink href={link} target="_blank">
            Vordruck
        </StyledLink>
        )
    </>
);

export default PrintOutLink;
