import { RedirectToDashboard } from '@/RedirectToDashboard';
import { AuthenticatedContent } from '@ten-netzkundenportal/ui-auth-utils';
import { LoadingSpinner } from '@ten-netzkundenportal/ui-components';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import '@ten-netzkundenportal/ui-installer-selection/dist/base.css';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, BrowserRouter as Router, Switch } from 'react-router-dom';

import ReactLazyPreload from './ReactLazyPreload';
import './base.css';
import ConnectionAssuranceRoot from './connection-assurance/ConnectionAssuranceRoot';
import './index.css';
import {
    CANCEL_CONTRACT,
    CANCEL_CONTRACT_CONFIRMATION,
    CONNECTION_ASSURANCE,
    CONTRACT_COMMISSIONING_DATA,
    CONTRACT_DETAILS_INSTALLER,
    CONTRACT_DOCUMENTS,
    CONTRACT_DOCUMENTS_UPLOAD,
    CONTRACT_FINISH_COMMISSIONING,
    CONTRACT_INSTALLER_SELECTION,
    CONTRACT_VIEW,
    ROOT_CONTRACT,
} from './routes';

export const MeasuringSpecificationContainer = ReactLazyPreload(
    () => import('./contract/commissioning-measuring-specification'),
);

export const ConclusionContainer = ReactLazyPreload(() => import('./contract/commissioning-conclusion'));
export const InstallerSelectionContainer = ReactLazyPreload(
    () => import('./contract/installer-selection/InstallerSelection'),
);
export const CancelContractContainer = ReactLazyPreload(() => import('./contract/cancel-contract/CancelContractView'));
export const ContractDocumentContainer = ReactLazyPreload(
    () => import('./contract/manage-documents/ContractDocumentView'),
);
export const ContractDocumentUploadContainer = React.lazy(
    () => import('./contract/manage-documents/ContractDocumentUploadForm'),
);

const CancelContractConfirmationContainer = React.lazy(
    () => import('./contract/cancel-contract-confirmation/CancelContractConfirmationView'),
);
export const ContractDetails = ReactLazyPreload(() => import('./contract/details-for-installer'));
export const ContractView = ReactLazyPreload(() => import('./contract/details/ContractView'));

export default (): React.ReactElement => (
    <AuthenticatedContent>
        <React.Suspense
            fallback={
                <div className="w-full h-[50rem] flex flex-col items-center">
                    <div className=" flex flex-row min-h-full flex-grow items-center">
                        <div className="w-20 h-20">
                            <LoadingSpinner />
                        </div>
                    </div>
                </div>
            }
        >
            <Router basename={ROOT_CONTRACT}>
                <Switch>
                    <Route path={CONNECTION_ASSURANCE}>
                        <ConnectionAssuranceRoot />
                    </Route>
                    <Route path={CONTRACT_VIEW} component={ContractView} />
                    <Route path={CONTRACT_INSTALLER_SELECTION} component={InstallerSelectionContainer} />
                    <Route path={CANCEL_CONTRACT} component={CancelContractContainer} />
                    <Route path={CANCEL_CONTRACT_CONFIRMATION} component={CancelContractConfirmationContainer} />
                    <Route path={CONTRACT_DOCUMENTS_UPLOAD} component={ContractDocumentUploadContainer} />
                    <Route path={CONTRACT_DOCUMENTS} component={ContractDocumentContainer} />

                    <Route path={CONTRACT_COMMISSIONING_DATA} component={MeasuringSpecificationContainer} />
                    <Route path={CONTRACT_FINISH_COMMISSIONING} component={ConclusionContainer} />
                    <Route path={CONTRACT_DETAILS_INSTALLER} component={ContractDetails} />

                    <Route
                        path="/:customerId/:processCommunicationId/dokumente"
                        render={(props: RouteComponentProps<{ processCommunicationId: string }>) => (
                            <Redirect to={`/${props.match.params.processCommunicationId}/dokumente`} />
                        )}
                    />
                    <Route
                        path="/:customerId/:processCommunicationId/dokumente-hochladen"
                        render={(props: RouteComponentProps<{ processCommunicationId: string }>) => (
                            <Redirect to={`/${props.match.params.processCommunicationId}/dokumente-hochladen`} />
                        )}
                    />
                    <Route
                        path="/installateur/:processCommunicationId/:commissioningProcessId/dokumente"
                        render={(props: RouteComponentProps<{ processCommunicationId: string }>) => (
                            <Redirect to={`/${props.match.params.processCommunicationId}/dokumente`} />
                        )}
                    />
                    <Route
                        path="/installateur/:processCommunicationId/:commissioningProcessId/dokumente-hochladen"
                        render={(props: RouteComponentProps<{ processCommunicationId: string }>) => (
                            <Redirect to={`/${props.match.params.processCommunicationId}/dokumente-hochladen`} />
                        )}
                    />
                    <Route
                        path="/installateur/:processCommunicationId/:commissioningProcessId/vertragsdetails"
                        render={(props: RouteComponentProps<{ processCommunicationId: string }>) => (
                            <Redirect
                                to={`/installateur/${props.match.params.processCommunicationId}/vertragsdetails`}
                            />
                        )}
                    />

                    <Route component={RedirectToDashboard} />
                </Switch>
            </Router>
        </React.Suspense>
    </AuthenticatedContent>
);
