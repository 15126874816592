import appConfig from '@/app.config';
import { ApiParams } from '@/hooks/useApi';
import { InstallerFilterResult } from '@ten-netzkundenportal/ui-installer-selection/';

const contractServiceUrl = appConfig.services.contractApi;

type Params = {
    connectionAssuranceId: string;
    installer: InstallerFilterResult;
};

const updateConnectionAssuranceInstaller = async ({ params, customerId, axios }: ApiParams<Params>): Promise<void> => {
    const { installer, connectionAssuranceId } = params;

    const result = await axios.patch(
        `${contractServiceUrl}/account/${customerId}/connection-assurance/${connectionAssuranceId}/installer`,
        installer,
    );

    if (result.status === 200) {
        return;
    }

    throw new Error('Failed to assign installer to connection-assurance.');
};
export default updateConnectionAssuranceInstaller;
