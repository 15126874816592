import { PlantComponent } from '../types/PlantComponent';

export const calculateSumOfActivePowersInKW = (plantComponents: PlantComponent[]) =>
    plantComponents
        .filter((item) => item && item.quantity && !Number.isNaN(item.quantity) && +item.quantity > 0)
        .filter((item) => item && item.activePower && !Number.isNaN(item.activePower))
        .map((item) => item.activePower * item.quantity)
        .reduce((prev, next) => prev + next, 0);

export const calculateSumOfApparentPowersInKVA = (plantComponents: PlantComponent[]) =>
    plantComponents
        .filter((item) => item && item.quantity && !Number.isNaN(item.quantity) && +item.quantity > 0)
        .filter((item) => item && item.apparentPower && !Number.isNaN(item.apparentPower))
        .map((item) => item.apparentPower * item.quantity)
        .reduce((prev, next) => prev + next, 0);
