import { TableTitle } from '@/connection-assurance/commissioning/components/TableTitle';
import { PlantComponent, PlantComponentType } from '@/types/PlantComponent';
import { UpdateContextFunction } from '@/types/UpdateContextFunction';
import { TextField } from '@ten-netzkundenportal/ui-components';
import React, { useRef, useState } from 'react';
import { ViewportList } from 'react-viewport-list';

import { PlantComponentProps } from '../CommissioningProcessProps';
import { PlantComponentEntry } from './PlantComponentEntry';
import { usePlantComponentsSearch } from './hooks/usePlantComponentsSearch';

type Props = {
    contextPlantComponents: PlantComponent[];
    plantComponentType: PlantComponentType;
    updateContext: UpdateContextFunction;
} & PlantComponentProps;

export const PlantComponentsTable = ({
    contextPlantComponents,
    plantComponentType,
    allPlantComponents,
    updateContext,
}: Props) => {
    const [filterValue, setFilterValue] = useState('');
    const tableRef = useRef<HTMLDivElement>(null);
    const contextPlantComponentsId =
        contextPlantComponents?.map((plantComponent) => plantComponent?.plantComponentId) ?? [];

    const filteredPlantComponentsData = usePlantComponentsSearch(
        allPlantComponents,
        filterValue,
        contextPlantComponentsId,
    );

    const scrollToTop = () => {
        if (tableRef && tableRef.current) {
            tableRef.current.scrollTo(0, 0);
        }
    };

    return (
        <div className="flex flex-col mb-16 space-y-10">
            <div className="flex flex-col gap-y-5 max-w-full">
                <div className="flex flex-col justify-center gap-y-2 h-28 align-center bg-blue-50 px-12 full-main-container-width">
                    <label className="text-sm font-bold" htmlFor="component-filter">
                        Komponente
                    </label>
                    <div className="items-center flex">
                        <TextField
                            placeholder="Suchen..."
                            value={filterValue}
                            id="component-filter"
                            additionalClassname="w-72"
                            data-testid="component-filter"
                            onChange={(e) => {
                                setFilterValue(e.currentTarget.value);
                                scrollToTop();
                            }}
                        />
                    </div>
                </div>
                <div className="w-full max-w-full relative">
                    <div className="grid grid-flow-col grid-cols-plant-component-table mr-2">
                        <TableTitle className="pl-3">Komponente</TableTitle>
                        <TableTitle className="px-3">Hersteller</TableTitle>
                        <TableTitle className="px-3">Scheinleistung</TableTitle>
                        <TableTitle className="px-3">Wirkleistung</TableTitle>
                        <TableTitle className="px-3">Anzahl</TableTitle>
                        <TableTitle className="text-center">Inselbetrieb</TableTitle>
                    </div>

                    <div
                        className="h-96 pr-2 overflow-auto custom-scrollbar scroll-smooth"
                        ref={tableRef}
                        style={{ scrollbarGutter: 'stable' }}
                    >
                        {contextPlantComponents.map((plantComponent) => (
                            <PlantComponentEntry
                                key={`${plantComponentType}-${plantComponent.plantComponentId}`}
                                plantComponent={plantComponent}
                                plantComponentType={plantComponentType}
                                contextPlantComponents={contextPlantComponents}
                                updateContext={updateContext}
                                tableRef={tableRef}
                                isSelected
                            />
                        ))}
                        <ViewportList items={filteredPlantComponentsData} viewportRef={tableRef}>
                            {(plantComponent) => (
                                <PlantComponentEntry
                                    key={`${plantComponentType}-${plantComponent.plantComponentId}`}
                                    plantComponent={plantComponent}
                                    plantComponentType={plantComponentType}
                                    contextPlantComponents={contextPlantComponents}
                                    updateContext={updateContext}
                                    tableRef={tableRef}
                                />
                            )}
                        </ViewportList>
                    </div>
                </div>
            </div>
            <span>
                <b>Hinweis: </b>
                Befindet sich Ihre gewünschte Komponente nicht in der Liste? Wenn Sie eine neue Komponente hinzufügen
                möchten, muss ein neuer Einspeisungsantrag gestellt werden.
            </span>
        </div>
    );
};
