import React, { useEffect, useState } from 'react';

import getConnectionAssurance from '../connection-assurance/api/getConnectionAssurance';
import useApi from '../hooks/useApi';
import { ConnectionAssurance } from '../types/ConnectionAssurance';

const useConnectionAssurance = (connectionAssuranceId: string) => {
    const getConnectionAssuranceApi = useApi(getConnectionAssurance);

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [connectionAssurance, setConnectionAssurance] = useState<ConnectionAssurance>();

    useEffect(() => {
        setIsLoading(true);
        getConnectionAssuranceApi({ connectionAssuranceId })
            .then((result) => {
                setConnectionAssurance(result);
            })
            .catch((e) => {
                console.error('Failed to load connection assurance', e);
                setConnectionAssurance(undefined);
            })
            .finally(() => setIsLoading(false));
    }, [getConnectionAssuranceApi, connectionAssuranceId]);

    return { connectionAssurance, isLoading };
};

export default useConnectionAssurance;
